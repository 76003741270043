import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { JiraService } from 'src/app/core/services/jira.service';
import { IntercomService } from 'src/app/core/services/intercom.service';

interface Tag {
  id: number;
  name: string;
  checked: boolean;
}

interface Labels {
  name: string;
  checked: boolean;
}

@Component({
  selector: 'app-attach-tag-modal',
  templateUrl: './attach-tag-modal.component.html',
  styleUrls: ['./attach-tag-modal.component.scss'],
})
export class AttachTagModalComponent implements OnInit {
  form: FormGroup;
  jiraForm: FormGroup;
  type: string = '';
  tags: Tag[] = [
    { id: 8391576, name: '⚡Escalated', checked: false },
    { id: 8087129, name: 'Complaint', checked: false },
  ];
  conversationExists: boolean = false;

  labels: Labels[] = [
    { name: 'Escalated⚡', checked: false },
    { name: 'Complaint', checked: false },
  ];
  selectedLabels: string[] = [];
  currentJiraTags: string[] = [];
  currentIntercomTags: string[] = [];
  currentTeam: number;
  ticketExists: boolean = false;
  jiraSummary: string = '';
  jiraAssignee: string = '';
  jiraReporter: string = '';
  currentUser: string = '';

  constructor(
    protected activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private jiraService: JiraService,
    private intercomService: IntercomService
  ) {}

  ngOnInit(): void {
    this.currentUser = localStorage.getItem('hub_username');
    this.generateForm();
  }

  close() {
    this.activeModal.dismiss();
  }

  getConversation(): void {
    this.conversationExists = false;
    this.helperService.showSpinner();
    this.intercomService
      .getConversation(this.form.controls['conversationId'].value.trim())
      .subscribe({
        next: (res) => {
          this.currentTeam = res.team_assignee_id;
          const currentTagsIds =
            res?.tags?.['tags']
              .filter((tag) => tag.id === '8391576' || tag.id === '8087129')
              .map((tag) => tag.id) ?? [];
          this.currentIntercomTags = [...currentTagsIds];
          this.selectedLabels = [...currentTagsIds];
          this.conversationExists = true;
          this.helperService.hideSpinner(false);
        },
        error: (err) => {
          if (err.status == 404) {
            this.helperService.showErrorToastr('Conversation does not exist.');
            this.helperService.hideSpinner(false);
          } else {
            this.helperService.openErrorModal('Fail to get conversation', err);
          }
        },
      });
  }

  onTypeChange(): void {
    this.conversationExists = false;
    this.currentJiraTags = [];
    this.selectedLabels = [];
    this.ticketExists = false;
    this.jiraForm.reset();
    this.form.reset();
  }

  getIssue(): void {
    this.ticketExists = false;
    this.helperService.showSpinner();
    this.jiraService
      .getIssue(this.jiraForm.controls['issue_id'].value.trim())
      .subscribe({
        next: (res) => {
          const currentTags = res.fields.labels ?? [];
          this.currentJiraTags = [...currentTags];
          this.selectedLabels = [...currentTags];
          this.jiraSummary = res.fields?.summary;
          this.jiraAssignee = res?.fields?.assignee?.displayName;
          this.jiraReporter = res?.fields?.reporter?.emailAddress
            ? res?.fields?.reporter?.emailAddress
            : res?.fields?.reporter?.displayName ?? 'Unknown';
          this.ticketExists = true;
          this.helperService.hideSpinner(false);
        },
        error: (err) => {
          if (err.status == 404) {
            this.helperService.showErrorToastr('Issue does not exist.');
            this.helperService.hideSpinner(false);
          } else {
            this.helperService.openErrorModal('Fail to get issue', err);
          }
        },
      });
  }

  updateLabels(label: Labels): void {
    const index = this.selectedLabels.indexOf(label.name);
    if (index >= 0) {
      this.selectedLabels.splice(index, 1);
    } else {
      this.selectedLabels.push(label.name);
    }
  }

  updateTags(tag: Tag): void {
    const index = this.selectedLabels.indexOf(tag.id.toString());
    if (index >= 0) {
      this.selectedLabels.splice(index, 1);
    } else {
      this.selectedLabels.push(tag.id.toString());
    }
  }

  updateJiraTicket(): void {
    this.helperService.showSpinner();
    const body = {
      user: this.currentUser,
      reason: this.jiraForm.controls['reason'].value,
      new_labels: this.selectedLabels,
      current_labels: this.currentJiraTags,
      summary: this.jiraSummary,
      assignee: this.jiraAssignee,
      reporter: this.jiraReporter,
    };
    this.jiraService
      .updateIssue(this.jiraForm.controls['issue_id'].value, body)
      .subscribe({
        next: () => {
          this.helperService.hideSpinner();
          this.helperService.showSuccessToastr('Ticket updated successfully!.');
        },
        error: (err) => {
          this.helperService.openErrorModal('Fail to update jira ticket', err);
        },
      });
  }

  updateIntercomChat(): void {
    this.helperService.showSpinner();
    const body = {
      user: this.currentUser,
      new_tags: this.selectedLabels,
      reason: this.form.controls['reason'].value,
      current_tags: this.currentIntercomTags,
      current_team: this.currentTeam,
    };
    this.intercomService
      .updateConversation(this.form.controls['conversationId'].value, body)
      .subscribe({
        next: () => {
          this.helperService.hideSpinner();
          this.helperService.showSuccessToastr('Ticket updated successfully!.');
        },
        error: (err) => {
          this.helperService.openErrorModal(
            'Fail to update intercom chat.',
            err
          );
        },
      });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      conversationId: new FormControl('', [Validators.required]),
      reason: new FormControl('', [Validators.required]),
    });
    this.jiraForm = this.formBuilder.group({
      issue_id: new FormControl('', [Validators.required]),
      reason: new FormControl('', [Validators.required]),
    });
  }
}

// example of a intercom chat 43092
// example of a jira ticket CS-274971
