<div class="modal-header">
  <div class="d-inline-flex align-items-center">
    <h4 class="modal-title me-2">Subscribe App</h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <label>New Waba ID</label>
    <div class="input-group">
      <input type="text" class="form-control" formControlName="waba_id">
    </div>
    <label>Phone Number</label>
    <div class="input-group">
      <input type="text" class="form-control" formControlName="phone_number">
    </div>
    <label>Country Code</label>
    <div class="input-group">
      <input type="text" class="form-control" formControlName="cc">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn-modal-proceed"
    type="submit"
    (click)="migratePhoneNumber()"
  >
    Migrate
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">
    Close
  </button>
</div>
