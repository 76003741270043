import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
}

if (environment.name != 'dev') {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
    ],
    environment: environment.name, // include environment
    // Set tracesSampleRate to 0.1 to capture 10%
    // of transactions for performance monitoring.
    // Do not overuse sentry performance quota
    tracesSampleRate: 0.1,

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [environment.sentry.tracePropagationTargets, /^\//],
  });

  Sentry.setUser({
    email: localStorage.getItem('hub_email') ?? null,
    fullName: localStorage.getItem('hub_username') ?? null
  });
}

if(!localStorage.getItem('loginType')) {
  localStorage.setItem('loginType', 'new');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
