import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  FormControl
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-single-dropdown-input-modal',
  templateUrl: './single-dropdown-input-modal.component.html',
  styleUrls: ['./single-dropdown-input-modal.component.scss'],
})
export class SingleDropdownInputModalComponent implements OnInit {
  form: FormGroup;
  title: string = '';
  inputFieldName: string = '';
  editInput: string = '';
  selectedItem: string = '';
  dropdownItems: any;

  constructor(
    protected activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  confirm() {
    this.activeModal.close(true);
    this.editInput = this.form.controls['editValue'].value;
  }

  close() {
    this.activeModal.dismiss();
  }

  dropdownSelected(event) {
    this.selectedItem = event;
    this.helperService.dropdownSelected(this.form, event, 'editValue');
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      editValue: new FormControl(''),
    });
  }
}
