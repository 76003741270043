import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { AuthenticationService as AuthService} from 'src/app/core/services/auth.service'
import { take } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  currentYear: number;
  oldLogin: boolean = false;
  loginType: string = 'new';

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private router: Router,
    public authService: AuthService
  ) {
    this.loginType = localStorage.getItem('loginType');
    if(this.loginType == 'old') {
      this.oldLogin = true;
    }
  }

  ngOnInit(): void {
    // if(localStorage.getItem('loginType') == 'new') {
    //   this.authService.isLoading$.subscribe({
    //     next: (loading) =>{
    //       if (loading) {
    //         this.helperService.showSpinner();
    //       } else {
    //         this.helperService.hideSpinner();
    //       }
    //     }
    //   })
    // }
    this.redirectIfLoggedIn();
    this.currentYear = new Date().getFullYear();
    this.generateForm();
  }

  onToggle(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.oldLogin = inputElement.checked;
    localStorage.setItem('loginType', this.oldLogin ? 'old' : 'new');
  }

  onClickSubmit(formValue) {
    this.helperService.showSpinner();
    this.authenticationService
      .loginWithEmailAndPassword(formValue.email, formValue.password)
      .subscribe({
        next: (res) => {
          localStorage.setItem('hub_token', res.access_token);
          localStorage.setItem('hub_email', formValue.email);
          const name = formValue.email
            .split('@')[0]
            .replace('.', ' ')
            .replace(/\b\w/g, (c) => c.toUpperCase());
          localStorage.setItem('hub_username', name);
          const redirectLink = localStorage.getItem('redirect_url');
          if(!!redirectLink) {
            if(redirectLink == '/login') {
              this.router.navigateByUrl('/phone');
            } else {
              window.location.href = redirectLink;
            }
          } else {
            this.router.navigateByUrl('/phone');
          }
        },
        error: (err) => {
          this.helperService.openErrorModal('Login failed', err);
        },
        complete: () => {
          this.helperService.hideSpinner();
        },
      });
  }

  redirectIfLoggedIn(): void {
    if (localStorage.getItem('loginType') == 'new') {
      this.authService.isLoggedIn$.pipe(take(1)).subscribe({
        next: (res) => {
          if (res === true) {
            const redirectLink = localStorage.getItem('redirect_url');
            if (redirectLink === '/login') {
              this.router.navigateByUrl('/phone');
            } else {
              window.location.href = redirectLink;
            }
          }
        }
      });
    } else {
      if (!!localStorage.getItem('hub_token')) {
        const redirectLink = localStorage.getItem('redirect_url');
        if (redirectLink === '/login') {
          this.router.navigateByUrl('/phone');
        } else {
          window.location.href = redirectLink;
        }
      }
    }
  }

  emailDomainValidator(validDomains: string[]) {
    return (control) => {
      const email = control.value;
      if (email) {
        const domain = email.substring(email.lastIndexOf('@') + 1);
        if (!validDomains.includes(domain)) {
          return { invalidDomain: true };
        }
      }
      return null;
    };
  }

  login(): void {
    localStorage.setItem('loginType', 'new');
    this.authService.login();
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, this.emailDomainValidator(['360dialog.com', 'get.chat'])]),
      password: new FormControl('', [Validators.required]),
    });
  }

}
