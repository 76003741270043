import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IntercomConversationModel } from '../domains/models/intercom.model';
import { IntercomReview } from '../domains/models/intercom-review.model';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  intercomRoute: string = `${environment.tppCsToolAPI.url}/v1/intercom`

  constructor(private httpClient: HttpClient) {}


  getConversation(conversationId: string): Observable<IntercomConversationModel> {
    return this.httpClient.get<IntercomConversationModel>(`${this.intercomRoute}/conversations/${conversationId}`, { params });
  }
  updateConversation(conversationId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(`${this.intercomRoute}/conversations/${conversationId}`, body);
  }

  downloadReviewDocument() {
    return this.httpClient.get(`${this.intercomRoute}/download-review-document`, { responseType: 'blob' });
  }

  getReviews(): Observable<IntercomReview[]> {
    return this.httpClient.get<IntercomReview[]>(`${this.intercomRoute}/get-reviews`);
  }

}
