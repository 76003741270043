<div class="modal-header">
  <div class="d-inline-flex align-items-center">
    <h4 class="modal-title me-2">{{ title }}</h4>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <label>{{ inputFieldName }}</label> <br />
      <div ngbDropdown class="d-inline-block w-100">
        <button type="button" class="form-control text-start arrow-right text-capitalize" id="dropdownBasic1"
          ngbDropdownToggle>
          {{ selectedItem }}
        </button>
        <div ngbDropdownMenu class="w-100">
          <button ngbDropdownItem class="border-bottom" *ngFor="let item of dropdownItems"
            (click)="dropdownSelected(item)">
            {{ item }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn-modal-proceed" type="submit" [disabled]="!this.form.valid" (click)="confirm()">
    Proceed
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">
    Close
  </button>
</div>