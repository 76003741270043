import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from './../services/cache/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(protected cache: CacheService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'GET' || req.url.includes('webhook')) {
      return next.handle(req);
    }

    if (req.params.get('reset') === 'true') {
      this.cache.delete(req.urlWithParams);

      const newParams = req.params.delete('reset');
      const clonedRequest = req.clone({ params: newParams });

      return next.handle(clonedRequest);
    }

    const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams);
    if (cachedResponse) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(req).pipe(
        tap((stateEvent) => {
          if (stateEvent instanceof HttpResponse) {
            this.cache.set(req.urlWithParams, stateEvent.clone());
          }
        })
      );
    }
  }
}
