<div class="modal-header">
  <div class="d-inline-flex align-items-center">
    <h4 class="modal-title me-2">Set Payment Method</h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="pb-2">
    <label>Waba External ID</label>
    <div class="input-group">
      <input type="text" class="form-control" formControlName="externalId">
      <div class="input-group-prepend">
        <button class="btn btn-primary" type="submit" (click)="checkWaba()">Check</button>
      </div>
    </div>
  </form>
  <div *ngIf="check && !loadingData">
    <h6 class="fb">WABA Users</h6>
    <table class="table bg-white">
      <thead>
        <tr>
          <th scope="col" *ngFor="let column of displayColumnsUsers">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of wabaUsers">
          <td class="align-middle">{{ user.id }}</td>
          <td class="align-middle">{{ user.name }}</td>
          <td class="align-middle">{{ user.tasks }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2 text-center" *ngIf="!containsWabaDev">
      <span class="text-danger">The WABA does not contain user waba_dev. <br></span>
      <button type="button" class="btn btn-primary" (click)="assignWabaDev()">Add waba_dev</button>
    </div>
    <div *ngIf="containsWabaDev">
      <h6 class="fb">WABA Info</h6>
      <ul class="list-group" *ngIf="!isObjectEmpty(wabaInfo); else loadingWabaInfo">
        <li class="list-group-item background-white">
          <span class="fb-700 me-1">ID: </span>
          <span>{{ wabaInfo?.id ?? 'N/A'}}</span>
        </li>
        <li class="list-group-item background-white">
          <span class="fb-700 me-1">Name: </span>
          <span>{{ wabaInfo?.name ?? 'N/A' }}</span>
        </li>
        <li class="list-group-item background-white">
          <span class="fb-700 me-1">Currency: </span>
          <span>{{ wabaInfo?.currency ?? 'N/A'}}</span>
        </li>
        <li class="list-group-item background-white">
          <span class="fb-700 me-1">Namespace: </span>
          <span>{{ wabaInfo?.message_template_namespace ?? 'N/A' }}</span>
        </li>
        <li class="list-group-item background-white">
          <span class="fb-700 me-1">Primary Funding ID: </span>
          <span>{{ wabaInfo?.primary_funding_id ?? 'N/A' }}</span>
        </li>
      </ul>
      <form [formGroup]="form" class="pt-2">
        <div class="form-group">
          <label>Waba Currency</label>
          <input class="form-control" formControlName="wabaCurrency" />
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn-modal-proceed"
    type="submit"
    [disabled]="!form.valid && !check"
    (click)="setPaymentMethod()"
  >
    Set
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">Close</button>
</div>

<ng-template #loadingWabaInfo>
  <span>Loading waba info...</span>
</ng-template>
