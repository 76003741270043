<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body p-3">
  <div *ngIf="description">
    <span class="text-break" [innerHTML]="description"></span>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger"
    type="button"
    (click)="confirm()"
  >
    {{ confirmBtnText }}
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="close()">
    Close
  </button>
</div>
