import { Injectable } from '@angular/core';
import { Channel } from 'src/app/core/domains/models/channels.module';

import * as _ from 'lodash';
import { HistoryChannel } from './../../domains/models/history-channel.model';

interface History {
  value: Channel;
  time: Date;
}

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private historyChannels: HistoryChannel[] = [];

  constructor() {}

  set(channel: Channel): void {
    const newChannel: HistoryChannel = {
      id: channel?.id ?? 'N/A',
      app_id: channel?.integration?.app_id ?? 'N/A',
      host_type: channel.integration?.hosting_platform_type ?? 'N/A',
      phone_number: channel?.setup_info?.phone_number ?? null,
      display_name: channel?.setup_info?.phone_name ?? 'N/A',
      partner_name: channel?.partner?.name ?? 'N/A',
      partner_id: channel?.partner?.id ?? 'N/A',
      client_id: channel?.client?.id ?? 'N/A',
      time: new Date(),
    };

    this.historyChannels = this.prepend(newChannel, this.historyChannels);
  }

  prepend(value: HistoryChannel, array: HistoryChannel[]) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  getAll(): HistoryChannel[] {
    return this.historyChannels;
  }
}
