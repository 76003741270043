import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../../domains/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  // auth0
  getUsers(type: string, value: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.authUser}/getUser/${type}/${value}`);
  }

  // user
  checkUser(email: string, reset: boolean = false): Observable<UserModel> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<UserModel>(`${environment.user}/checkUser/${email}`, { params });
  }

  // get all users
  getAllUsers(reset: boolean): Observable<UserModel[]> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<UserModel[]>(`${environment.user}/getAllUsers`);
  }

  // get user by email
  getUserByEmail(email: string): Observable<UserModel[]> {
    return this.httpClient.get<UserModel[]>(`${environment.user}/getUser/${email}`);
  }

  // update user
  updateUser(user): Observable<any> {
    return this.httpClient.post<any>(`${environment.user}/updateUser`, user );
  }

  // update user
  updateUserPreference(userId: string, preference: {}): Observable<UserModel> {
    return this.httpClient.post<UserModel>(`${environment.user}/${userId}/updateUserPreferences`, preference);
  }

  // delete user
  deleteUser(userId): Observable<any> {
    return this.httpClient.delete<any>(`${environment.user}/deleteUser/${userId}`);
  }
}
