<div class="container-fluid">
  <hr>
  <div class="row" *ngIf="oboToEsVal?.host_type != 'meta_cloud_api'">
    <div class="col-12 text-center">
      <span>At this point the number is located in the new waba. To verify this information click on this <a
          class="new-waba-link" target="_blank"
          [href]="'https://business.facebook.com/wa/manage/phone-numbers/?business_id=734443296905665&waba_id=' + oboToEsVal.new_waba_id">link</a>
        and see if the number is present there.</span>
      <img src="../../../../../assets/images/es-images/es_img_1.jpg" class="img-fluid mt-1" alt="Es img 1">
    </div>
    <div class="col-12 text-center p-1">
      <span>Next step is to disable 2FA on the source WABA</span>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center">
        <button type="button" class="btn-modal-proceed" type="submit" [ngStyle]="{ width: '150px' }"
          (click)="disalbeMfaPin()">
          Disable 2FA
        </button>
      </div>
    </div>
    <div class="col-12 text-center" *ngIf="oboToEsVal?.step_two_completed == 1">
      <span>Please check if the 2FA is disabled. You can do so by clicking on this <a class="new-waba-link"
          target="_blank"
          [href]="'https://business.facebook.com/wa/manage/phone-numbers/?business_id=734443296905665&waba_id='+ oboToEsVal?.current_waba_id + '&phone_number=' + oboToEsVal?.phone_number + '&childRoute=PHONE_PROFILE%2FRECOVERY'">link</a>
        and confirm that the 2FA is not enabled.</span>
        <img src="../../../../../assets/images/es-images/es_img_2.jpg" class="img-fluid mt-1" alt="Es img 2">
    </div>
    <div class="col-12" *ngIf="oboToEsVal?.step_two_completed == 1">
      <div class="d-flex justify-content-end">
        <button type="button" class="btn-modal-proceed" type="submit" [ngStyle]="{ width: '75px' }"
          (click)="navigateToNextStep()">
          Next
        </button>
      </div>
    </div>
    <div class="col text-center pt-1" *ngIf="errorMessage">
      <span class="text-danger">{{ errorMessage }}</span>
    </div>
  </div>
  <div class="row" *ngIf="oboToEsVal?.host_type == 'meta_cloud_api'">
    <div class="col-12 text-center">
      <span>At this point the number is located in the new waba. To verify this information click on this <a
          class="new-waba-link" target="_blank"
          [href]="'https://business.facebook.com/wa/manage/phone-numbers/?business_id=734443296905665&waba_id=' + oboToEsVal?.new_waba_id">link</a>
        and see if the number is present there.</span>
      <img src="../../../../../assets/images/es-images/es_img_1.jpg" class="img-fluid mt-1" alt="Es img 1">
    </div>
    <div class="col-12 mt-1">
      <span>Next step is to disable 2FA on the source WABA and since this is an Cloud API number the action needs to be
        done manually:</span>
      <ul class="list-group list-group-flush">
        <li class="list-group-item ps-0">
          <span>1. Click on this <a class="new-waba-link" target="_blank"
              [href]="'https://business.facebook.com/wa/manage/phone-numbers/?business_id=734443296905665&waba_id='+ + oboToEsVal?.current_waba_id + '&phone_number=' + + oboToEsVal?.phone_number + '&childRoute=PHONE_PROFILE%2FRECOVERY'">link.</a></span>
        </li>
        <li class="list-group-item ps-0">2. Click on 'Turn off two-step verification' after which you will receive an email to you @360dialog account.</li>
        <li class="list-group-item ps-0">3. Follow the steps from the email.</li>
        <li class="list-group-item ps-0">4. Refesh the page where the 2FA was enabled and confirm that it is disabled.</li>
      </ul>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <button type="button" class="btn-modal-proceed" type="submit" [ngStyle]="{ width: '75px' }"
          (click)="navigateToNextStep()">
          Next
        </button>
      </div>
    </div>
  </div>
</div>
