import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { HelperService } from './helper/helper.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UsersService } from './userService/users.service';
import { SharedDataService } from './shared-data/shared-data.service';
import { AuthUserModel } from '../domains/models/auth-user.model';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  isLoading$ = this._isLoading$.asObservable();
  private destroy$ = new Subject<void>();
  user: AuthUserModel = {};

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private auth0Service: AuthService,
    private helperService: HelperService,
    private httpClient: HttpClient,
    private router: Router,
    private userService: UsersService,
    private sharedDataService: SharedDataService
  ) {
    if(localStorage.getItem('loginType') == 'new') {
      this.initialize();
    }
  }

  login(): void {
    localStorage.setItem('loginType', 'new');
    this.auth0Service.loginWithPopup().subscribe({
      error: (error) => {
        if (error?.error_description) {
          if (error.error_description == 'Popup closed') return;
          this.helperService.showErrorToastr(error.error_description);
        } else {
          this.helperService.showErrorToastr('Unable to login. Please post the issue to #cs-tool channel.');
        }
        this.clearAuthState();
      },
      complete: () => this.initialize()
    });
  }
  clearAuthState(): void {
    const originalUrl = window.location.href;
    const parsedUrl = new URL(originalUrl);
    const path = parsedUrl.pathname + parsedUrl.search;
    localStorage.setItem('redirect_url', path);
    Object.keys(localStorage).forEach(key => {
      if (key !== 'redirect_url') {
        localStorage.removeItem(key);
      }
    });
    this.auth0Service.logout({ logoutParams: { returnTo: this.doc.location.origin } })
  }
  permissionCheck(): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.tppCsToolAPI.url}/v1/authorization/permission_check`, {params}
    );
  }

  tokenCheck(): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.tppCsToolAPI.url}/v1/authorization/token_check`, {params}
    );
  }

  getUserProfile(): Observable<AuthUserModel> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<AuthUserModel>(
      `${environment.tppCsToolAPI.url}/v1/authorization/user_profile`, {params}
    );
  }

  private initialize(): void {
    localStorage.setItem('loginType', 'new');
    combineLatest([this.auth0Service.isAuthenticated$, this.auth0Service.isLoading$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isAuthenticated, isLoading]) => {
        this._isLoading$.next(isLoading);
        if (isAuthenticated) {
          this.auth0Service.user$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async user => {
              this.checkUser(user?.email);
              this.checkPermissions();
              this._isLoggedIn$.next(true);
              this.user = user;
              this.setInfoToLocalStorage(user?.email);
            });
        }
      });
  }

  private setInfoToLocalStorage(email: string): void {
    localStorage.setItem('hub_email', email);
    const name = email
      .split('@')[0]
      .replace('.', ' ')
      .replace(/\b\w/g, (c) => c.toUpperCase());
    localStorage.setItem('hub_username', name);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkPermissions(): void {
    this.getUserProfile().subscribe({
      next: (res) => {
        this.sharedDataService.setUserValue(res);
      }
    });
  }

  private redirectUser(): void {
  const redirectLink = localStorage.getItem('redirect_url');
  if(redirectLink == '/login') {
    this.router.navigateByUrl('/phone');
  } else {
    window.location.href = redirectLink;
  }
}

private checkUser(email: string) {
  this.userService.checkUser(email, true).subscribe({
    next: (res) => {
      this.sharedDataService.setUser(res);
    },error: (err) => {}
  });
}
}