<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-center">
      <span>
        In this step we will trigger/verify the phone number via OTP. Please make sure that the client is able to receive it by at least one method.
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="triggerOtpForm" class="pb-2">
        <label class="p-1">Method</label>
        <div class="input-group">
          <div ngbDropdown class="d-inline-block w-86">
            <button
              type="button"
              class="form-control text-start arrow-right text-capitalize dropdown-extras"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              {{ selectedDropdown }}
            </button>
            <div ngbDropdownMenu class="w-86">
              <button
                ngbDropdownItem
                class="border-bottom"
                (click)="dropdownSelected('sms')"
              >
                Sms
              </button>
              <button
                ngbDropdownItem
                (click)="dropdownSelected('voice')">
                Voice
              </button>
            </div>
          </div>
          <div class="input-group-prepend">
            <button class="btn btn-primary btn-radius" type="submit" [disabled]="!triggerOtpForm.valid" (click)="triggerOtp()">Trigger</button>
          </div>
        </div>
        <small class="form-text text-muted">
          If you are having trougble verifying with one method or it is locked, try the second one.
        </small>
      </form>
    </div>
  </div>
  <div class="row">
    <hr>
    <div class="col-12">
      <label class="p-1">If you've already triggered the OTP and have the code please enter it here.</label>
      <form [formGroup]="verifyOtpForm">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">OTP</span>
          </div>
          <input type="text" maxlength="6" class="form-control" formControlName="code" />
          <div class="input-group-append">
            <button class="btn btn-primary btn-radius" type="submit" [disabled]="!verifyOtpForm.valid"
              (click)="handleThirdStep()">Verify</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col text-center pt-1" *ngIf="errorMessage">
      <span class="text-danger">{{ errorMessage }}</span>
    </div>
  </div>
</div>
