<div *ngIf="(authService.isLoggedIn$ | async) || (newAuthService.isLoggedIn$ | async); else loginInfo" [ngClass]="{'cs-tool-dark-mode': isDarkMode}">
  <app-top-bar></app-top-bar>
  <div class="position-relative">
    <router-outlet></router-outlet>
  </div>
  <button type="button" class="btn-copy" ngbTooltip="Copy current URL to clipboard" (click)="copyText()">
    <img src="../../../assets/images/icons/copy-icon.svg" alt="copy-icon" />
  </button>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.1)" type="ball-scale-multiple">
  <div class="custom-spinner d-inline-flex align-items-center px-3 py-2">
    <div class="spinner-border text-light me-2"></div>
    <span>Loading...</span>
  </div>
</ngx-spinner>

<ng-template #loginInfo>
  <div class="container-fluid login-bg">
    <app-login appSpinner></app-login>
  </div>
</ng-template>