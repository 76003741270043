import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      return next.handle(this.addAuthToken(request));
  }

  private addAuthToken(request: HttpRequest<any>) {
    this.authService.tokenExpired();
    if (request.url.includes(environment.hubWabaApi)) {
      return request;
    }
    if (request.url.includes(environment.tppCsToolAPI.url)) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('hub_token')}`,
          'X-API-KEY': `${environment.tppCsToolAPI.token}`
        },
      });
    }
    if (request.url.includes(environment.tosApi.url)) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${environment.tosApi.token}`
        },
      });
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('hub_token')}`,
      },
    });
  }
}
