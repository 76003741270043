<form class="search d-flex" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="d-flex search-container">
    <app-dropdown [selectedItem]="selectedSearchType" [dropdownItems]="searchTypes"
      (dropdownSelection)="searchTypeSelected($event)"></app-dropdown>
    <div class="search-input-container">
      <img type="button" (click)="onSubmit()" class="search-icon" src="assets/images/icons/search.svg" alt="" />
      <input type="text" class="search-input" placeholder="Search..." formControlName="searchInput"
        (ngModelChange)="setSearch($event)" (keyup)="deletInput($event)" />
    </div>
  </div>
</form>