import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-photo-modal',
  templateUrl: './upload-photo-modal.component.html',
  styleUrls: ['./upload-photo-modal.component.scss'],
})
export class UploadPhotoModalComponent {
  unsupportedFileType: boolean = false;
  title: string = '';
  url: string = '';
  description: string = '';
  file: FormData;

  constructor(protected activeModal: NgbActiveModal) {}

  onSelect(event) {
    let fileType = event.target.files[0].type;
    if (fileType.match(/image\/*/)) {
      let reader = new FileReader();
      const file = new FormData();
      file.set('file', event.target.files[0]);
      this.file = file;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
    } else {
      this.unsupportedFileType = true;
    }
  }

  confirm() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.dismiss();
  }
}
