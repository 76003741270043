export enum FilterTypeEnum {
  phoneNumber = "setup_info.phone_number",
  appId = "integration.app_id",
  stackId = "integration.stack_id",
  facebookBusinessId = "waba_account.on_behalf_of_business_info.id",
  wabaName = "waba_account.name",
  wabaExternalId = "waba_account.external_id",
  projectName = "project.name",
  partnerId = "partner_id",
  clientId = "client.id",
  clientName = "client.name",
  clientEmail = "client.contact_info.email",
  channelId = "id",
  wabaId = "waba_account.id",
}

export enum FilterPartnerTypeEnum {
  partnerId = "id",
  partnerName = "name"
}
