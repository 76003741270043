<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <label>{{ inputFieldName }}</label>
      <input
        [type]="inputType"
        class="form-control"
        formControlName="editValue"
      />
    </div>
    <div *ngIf="maxLength && form.get('editValue').errors?.['maxlength']">
      <span class="text-danger"
        >Value can be max {{ maxLength }} characters long.</span
      >
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn-modal-proceed"
    type="submit"
    [disabled]="!this.form.valid"
    (click)="confirm()"
  >
    {{ btnLabel }}
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">Close</button>
</div>
