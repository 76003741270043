import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Channel } from 'src/app/core/domains/models/channels.module';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { OboToSharedService } from 'src/app/core/services/obo-to-shared/obo-to-shared.service';

@Component({
  selector: 'app-obo-to-shared-modal',
  templateUrl: './obo-to-shared-modal.component.html',
  styleUrls: ['./obo-to-shared-modal.component.scss']
})
export class OboToSharedModalComponent implements OnInit {

  @ViewChild(CdkStepper) stepper!: CdkStepper;
  channel: Channel;
  selectedIndex: number;
  stepOneCompleted: boolean = false;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  stepFourCompleted: boolean = false;

  constructor(
    private helperService: HelperService,
    private activeModal: NgbActiveModal,
    private csToolApiService: CsToolApiService,
    private oboToSharedService: OboToSharedService
  ) {}

  ngOnInit(): void {
    this.getMigrationProcess();
  }

  close() {
    this.activeModal.dismiss();
  }

  changedStep(event) {
    if (event == 'stepOne') {
      this.selectedIndex = 1;
      this.stepOneCompleted = true;
    } else if (event == 'stepTwo') {
      this.selectedIndex = 2;
      this.stepOneCompleted = true;
      this.stepTwoCompleted = true;
    } else if (event == "stepThree") {
      this.selectedIndex = 3;
      this.stepOneCompleted = true;
      this.stepTwoCompleted = true;
      this.stepThreeCompleted = true;
    } else if (event == "completed") {
      this.stepOneCompleted = true;
      this.stepTwoCompleted = true;
      this.stepThreeCompleted = true;
      this.stepFourCompleted = true;
    }
  }

  private getMigrationProcess() {
    this.helperService.showSpinner();
    this.csToolApiService.getMigration(
      this.channel?.waba_account?.external_id,
      this.channel.setup_info.phone_number
      ).subscribe({
        next: (res) => {
          if (!this.helperService.isObjectEmpty(res)) {
            this.oboToSharedService.setPayload(res);
            if (res.step_three_completed) {
              this.changedStep('stepThree');
            } else if (res.step_two_completed === 1) {
              this.changedStep('stepTwo');
            } else if (res.step_one_completed === 1) {
              this.changedStep('stepOne');
            }
          }
          this.helperService.hideSpinner(false);
        }
      });
  }
}

