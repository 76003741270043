<div class="row">
  <div class="col">
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn" [ngClass]="buttonOptions?.color ? buttonOptions.color : 'btn-outline-primary'"
        ngbDropdownToggle>
        {{ selectedItem ? selectedItem : defaultDropdownOption }}
      </button>
      <div ngbDropdownMenu>
        <button type="button" ngbDropdownItem disabled *ngIf="defaultDropdownOption">
          {{ defaultDropdownOption }}
        </button>
        <button *ngFor="let dropdownItem of dropdownItems" ngbDropdownItem type="button"
          (click)="dropdownItemSelected($event)" [value]="dropdownItem">
          {{ dropdownItem }}
        </button>
      </div>
    </div>
  </div>
</div>