<div class="container-fluid">
  <div class="row">
    <div class="col border-bottom d-flex align-items-center pagetitle px-3">
      <div>
        <h1 class="my-0 pt-3">Action</h1>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col p-2">
      <table class="table design-table">
        <thead>
          <tr>
            <th scope="col" *ngFor="let column of displayColumns">
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="align-middle">Escalations and Complaints</td>
            <td class="align-middle">
              Used to attach esacalated/complaint to either Intercom or Jira.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" (click)="attachTag()">
                <i class="bi bi-ticket" width="16" height="16"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Email History</td>
            <td class="align-middle">
              Used to verify if OTP has been delivered to an email/unblock an email.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" (click)="openSendgridModal()">
                <i class="bi bi-envelope-check-fill" width="16" height="16"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Delete User</td>
            <td class="align-middle">
              Delete ES user so that he can redo the Embedded Signup flow
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Delete ES User"
                (click)="openModal(deleteESModal)">
                <img src="../../../assets/images/icons/trash-icon.svg" alt="trash-icon" />
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Re-create Stack</td>
            <td class="align-middle">
              If you need to re-create a stack that has been deleted use this
              feature
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Re-create Stack"
                (click)="openModal(undeleteStackModal, 'md')">
                <img src="../../../assets/images/icons/refresh-icon.svg" alt="refresh-icon" />
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Prepare Migration</td>
            <td class="align-middle">
              If you need to manualy port a number use this feature. Please make
              sure that the previous channel is deleted
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Port Number"
                (click)="openModal(numberPortingModal)">
                <img src="../../../assets/images/icons/link.svg" alt="link" />
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Validate Facebook ID</td>
            <td class="align-middle">
              If you need to validate facebook ID for the client use this
              feature
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Validate Facebook ID" (click)="validateFbId()">
                <img src="../../../assets/images/icons/check-circle.svg" alt="check-circle" />
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Set Payment Method</td>
            <td class="align-middle">
              If the waba is missing payment method, you can use this feature to set it.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Set Payment Method"
                (click)="setPaymentMethod()">
                <i class="bi bi-cash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Subscribe App</td>
            <td class="align-middle">
              Used for cloud API numbers when ported from another BSP (re-migrate process).
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Subscribe App" (click)="subscribeApp()">
                <i class="bi bi-bookmark-plus-fill"></i>
              </button>
            </td>
          </tr>
          <!-- <tr>
            <td class="align-middle">Transfer number to another WABA</td>
            <td class="align-middle">
              Used for OBO->ES.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Migrate Number" (click)="migratePhone()">
                <img src="../../../assets/images/icons/arrow-right-icon.svg" width="16" height="16"
                  alt="arrow-right-icon" />
              </button>
            </td>
          </tr> -->
          <tr>
            <td class="align-middle">Flag for re-onboarding</td>
            <td class="align-middle">
              Used when the number was previously added to the hub but was deleted in the meantime. ES will fail for the
              client.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Flag for re-onboarding"
                (click)="openModal(flagForReOnboardingModal, 'lg')">
                <i class="bi bi-flag"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Deleted channel information</td>
            <td class="align-middle">
              Display informatio related to deleted channel and refund balance for the client if needed.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Get Deleted Channel"
                (click)="openDeletedChannelModal()">
                <i class="bi bi-archive-fill"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Migrate Templates</td>
            <td class="align-middle">
              Use the WhatsApp Business Account > Migrate Message Templates endpoint to migrate templates from one of
              your WhatsApp Business Accounts to another.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Migrate templates from one waba to another"
                (click)="openModalClear(migrateTemplateModal)">
                <i class="bi bi-c-square-fill"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Get PCR Client balance</td>
            <td class="align-middle">
              When a client changes partner from direct -> partner payed you can check the balance prior to that change
              with this feature.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" (click)="openModalClear(clientPcrModal, 'form')">
                <i class="bi bi-cash-stack"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="align-middle">Enable outbound messages</td>
            <td class="align-middle">
              Allow outbound messaging for all client channels.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" (click)="allowOutboundMessages()">
                <i class="bi bi-envelope-arrow-up-fill"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="isAdmin">
            <td class="align-middle">Revoke credit line</td>
            <td class="align-middle">
              If the client migrated to another BSP use this feature to revoke 360dialog credit line.
            </td>
            <td class="d-flex flex-row">
              <button type="button" class="btn-custom me-2" ngbTooltip="Remove credit line"
                (click)="openModal(revokeCreditLineModal)">
                <i class="bi bi-credit-card-fill" width="16" height="16"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- DELETE ES USER MODAL -->
<ng-template #deleteESModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete User</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="mb-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">User Email</span>
          </div>
          <input type="text" class="form-control" formControlName="userEmail" />
        </div>
      </div>
    </form>
    <div *ngIf="actionMessage">
      <span class="text-danger text-break">{{ actionMessage }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" type="submit" [disabled]="!form.valid" (click)="deleteEsUser()">
      Delete
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>

<!-- UNDELETE STACAK MODAL -->
<ng-template #undeleteStackModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Re-create Stack</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      Don't know the app_id? Search SLACK channel
      <a href="https://360dialog.slack.com/archives/C012DV6UNRY" target="_blank">#whatsapp-devops-bot</a>
      for the stack_id
    </p>
    <form [formGroup]="form">
      <div class="mb-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">App ID</span>
          </div>
          <input type="number" class="form-control" formControlName="appId" />
        </div>
      </div>
    </form>
    <div *ngIf="actionMessage">
      <span class="text-breaktext-danger">{{ actionMessage }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" type="submit" (click)="reCreateStack()">
      Proceed
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>

<!-- UNDELETE STACAK MODAL -->
<ng-template #numberPortingModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Port Number</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Partner ID</span>
            </div>
            <input type="text" class="form-control" formControlName="partnerId" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Client ID</span>
            </div>
            <input type="text" class="form-control" formControlName="clientId" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Waba Account ID</span>
            </div>
            <input type="text" class="form-control" formControlName="wabaAccId" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Phone Number</span>
            </div>
            <input type="text" class="form-control" formControlName="phoneNumber" />
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="actionMessage">
      <span class="text-break" [ngClass]="actionError ? 'text-danger' : 'text-success'">{{ actionMessage }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" type="submit" (click)="portNumber()">
      Proceed
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #revokeCreditLineModal let-modal>
  <div class="modal-header">
    <div class="d-inline-flex align-items-center">
      <h4 class="modal-title me-2">Revoke Credit Line</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="revokeCreditLineForm">
      <div class="form-group mt-1">
        <span>Business Manager ID</span>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="business_manager_id">
          <span class="input-group-append m-0">
            <button class="btn rr bg-primary text-white" type="button" (click)="getAllocationId()">
              Check
            </button>
          </span>
        </div>
      </div>
      <div *ngIf="!isObjectEmpty(allocationObject)" class="pt-1">
        <ngx-json-viewer [json]="allocationObject"></ngx-json-viewer>
      </div>
      <div *ngIf="allocationIdExists">
        <div class="form-group mt-1">
          <span>Allocation ID</span>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="allocation_id">
            <span class="input-group-append m-0">
              <button class="btn rr bg-danger text-white" type="button" (click)="deleteCreditLine()">
                Remove Credit Line
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="pt-1" *ngIf="creditLineDeleted">
        <ngx-json-viewer [json]="verifyObject"></ngx-json-viewer>
      </div>
      <div *ngIf="actionError" class="pt-1">
        <span class="text-danger">{{ actionMessage | json }}</span>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #flagForReOnboardingModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Flag for re-onboarding</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-10">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Channel ID</span>
            </div>
            <input [(ngModel)]="channelId" type="text" class="form-control" />
          </div>
        </div>
        <div class="col-2">
          <button class="bi bi-backspace btn btn-outline-dark" (click)="clear()" ngbTooltip="Clear"></button>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" type="submit" [disabled]="!channelId"
        (click)="flagForReOnboarding()">
        Flag for re-onboarding
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">
        Close
      </button>
    </div>
  </div>

</ng-template>

<ng-template #migrateTemplateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Migrate templates from one waba to another</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>- Templates can only be migrated between WhatsApp Business Accounts owned by the same Meta business.</p>
          <p>- Only templates with a status of APPROVED and a quality_score of either GREEN or UNKNOWN are eligible for
            migration.</p>
        </div>
        <div class="col-12 p-2">
          <form [formGroup]="migrateTemplatesForm">
            <div class="form-group">
              <label>Source WABA ID</label>
              <input type="text" class="form-control" formControlName="source_waba">
            </div>
            <div class="form-group">
              <label>Destination WABA ID</label>
              <input type="text" class="form-control" formControlName="destination_waba">
            </div>
          </form>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" type="submit" (click)="migrateTemplates()">
        Submit
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #clientPcrModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Client PCR Info</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>- When a client changes partner from direct -> partner payed you can check the balance prior to that change
            with this feature.</p>
        </div>
        <div class="col-12 px-2 pb-2">
          <form [formGroup]="form">
            <div class="form-group">
              <label>Partner ID</label>
              <input type="text" class="form-control" formControlName="partnerId">
            </div>
            <div class="form-group">
              <label>Client ID</label>
              <input type="text" class="form-control" formControlName="clientId">
            </div>
          </form>
        </div>
      </div>
      <div class="container" *ngIf="clientPcrInfo">
        <div *ngIf="clientPcrInfo?.count == 0">
          <div class="alert alert-info" role="alert">
            No data available.
          </div>
        </div>
        <div *ngIf="clientPcrInfo?.count != 0">
          <div *ngFor="let item of clientPcrInfo?.partner_change_requests" class="card mb-3">
            <div class="card-header">
              Partner Change Request
            </div>
            <div class="card-body">
              <p class="card-text">- Created at: {{ item?.created_at ?? "N/A" }}</p>
              <p class="card-text">- Migration Date: {{ item?.migration_date ?? "N/A" }}</p>
              <p class="card-text">- New Partner ID: {{ item?.new_partner_id ?? "N/A" }}</p>
              <p class="card-text">- Old Partner ID: {{ item?.old_partner_id ?? "N/A" }}</p>
              <p class="card-text">- Status: {{ item?.status ?? "N/A" }}</p>
              <p class="card-text">- Balance: {{ item?.billing_info?.client_balance ?? "N/A" }} {{
                item?.billing_info?.currency ?? '' }}</p>
              <p>Channels:</p>
              <div *ngFor="let channel of item?.billing_info?.channels" class="mb-2">
                <p class="card-text">-- Phone number: {{ channel?.phone_number ?? "N/A" }} </p>
                <p class="card-text">-- Balance: {{ channel?.channel_balance ?? "N/A" }} </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" type="submit" (click)="getClientPcrInfo()">
        Check
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">
        Close
      </button>
    </div>
  </div>
</ng-template>