import {
  Component,
  OnChanges,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-dropdown-links",
  templateUrl: "./dropdown-links.component.html",
  styleUrls: ["./dropdown-links.component.scss"],
})
export class DropdownLinksComponent {
  @Input() dropdownItems: Array<any>;
  @Input() defaultDropdownOption: string;
}
