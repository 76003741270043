import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { HubService } from "src/app/core/services/hub/hub.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from "src/app/core/services/helper/helper.service";
import { Channel } from "src/app/core/domains/models/channels.module";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";


@Component({
  selector: 'app-deleted-channel',
  templateUrl: './deleted-channel.component.html',
  styleUrls: ['./deleted-channel.component.scss']
})
export class DeletedChannelComponent {
  clientId: string;
  partnerId: string;
  search: boolean = false;
  channels: Channel[] = [];
  displayColumns: Array<string> = ["ID", "Phone Number", "Display Name", "WABA ID", "Cancelled At", "Terminated At", "Last Modified By"];

  constructor(
    protected activeModal: NgbActiveModal,
    private hubService: HubService,
    private toastrService: ToastrService,
    private helperService: HelperService,
    private modalService: NgbModal
  ) {}

  getDeletedChannel() {
    this.search = false;
    if (!this.clientId || !this.partnerId) {
      this.toastrService.error("Please populate each field");
      return;
    }
    this.helperService.showSpinner();
    this.hubService.getDeletedChannel(this.clientId, this.partnerId).subscribe({
      next: (res) => {
        this.helperService.hideSpinner(false);
        this.channels = res;
        this.search = true;
      },
      error: (err) => {
        this.helperService.openErrorModal('Fail to fetch channel information', err);
      },
    });
  }

  refundBalance(): void {
    const modalInstance: NgbModalRef = this.modalService.open(
      ConfirmationModalComponent,
      { centered: true }
    );
    modalInstance.componentInstance.title =
      'Refund balance for every cahnnel?';
    modalInstance.componentInstance.description =
      `If you click Confirm the balance will be refunded for every channel related to ${this.clientId}?`;
    modalInstance.componentInstance.confirmBtnText = 'Confirm';
    modalInstance.result
      .then((dismissData: any) => {
        if (dismissData) {
          this.helperService.showSpinner();
          this.hubService.refundUnusedFunds(this.clientId, this.partnerId).subscribe({
            next: (res) => {
              this.helperService.hideSpinner();
              this.helperService.openErrorModal('Refund status.', res);
              this.helperService.showSuccessToastr('Refund completed.');
            },
            error: (err) => {
              this.helperService.openErrorModal('Failed to refund balance.', err);
            }
          });
        }
      })
      .catch((reason) => {
      });
  }

  close() {
    this.activeModal.dismiss();
  }
}
