import { Injectable } from '@angular/core';
import { OboToSharedModel } from '../../domains/models/obo-to-shared.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OboToSharedService {

  constructor() { }

  private _oboToSaredVal$ = new BehaviorSubject({});
  oboToSharedVal$ = this._oboToSaredVal$.asObservable();

  setPayload(payload: OboToSharedModel) {
    this._oboToSaredVal$.next(payload);
  }

  parseErrorMessage(error): string {
    return error['error']['errorMessage'];
  }
}
