import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, catchError, forkJoin, of } from 'rxjs';
import { AuthUserModel } from 'src/app/core/domains/models/auth-user.model';
import { Messages } from 'src/app/core/domains/models/sendgrid.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { SendgridService } from 'src/app/core/services/sendgrid.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';

@Component({
  selector: 'app-sendgrid',
  templateUrl: './sendgrid.component.html',
})
export class SendgridComponent implements OnInit, OnDestroy {
  @Input() email: string;
  @Input() action: boolean = false;

  subscriptions: Subscription[] = [];

  form: any;
  search: boolean = false;
  messagesCount: number = 0;
  messages: Messages = {};
  displayColumns: string[] = [
    'From Email',
    'To Email',
    'Subject',
    'Time',
    'Status',
    'Action',
  ];
  singleMessageSearch: boolean = false;
  messageEvents: any;
  sendgridWrite: boolean = false;
  suppressionExist: boolean = false;
  blocks: [] = [];
  bounces: [] = [];
  spams: [] = [];
  unsubscribes: [] = [];

  private readonly SENDGRID_WRITE_PERMISSION = 'sendgrid:write';

  constructor(
    private helperService: HelperService,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private sendgridService: SendgridService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.checkUser();
    if (!this.email) {
      this.generateForm();
    } else {
      this.checkEmail();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  checkEmail(): void {
    this.search = false;
    this.singleMessageSearch = false;
    this.suppressionExist = false;
    const email = this.action
      ? this.form.controls['email'].value.trim()
      : this.email;
    this.helperService.showSpinner();
    this.sendgridService.getMessagesByEmail(email).subscribe({
      next: (res) => {
        this.messagesCount = res?.messages.length;
        this.search = true;
        this.messages = res;
        this.helperService.hideSpinner(false);
      },
      error: (error) =>
        this.helperService.openErrorModal('Fail to fetch emails', error),
    });
  }

  checkSuppression(): void {
    this.suppressionExist = false;
    this.helperService.showSpinner();
    const email = this.action
      ? this.form.controls['email'].value.trim()
      : this.email;

    const block$ = this.sendgridService.getBlockByEmail(email).pipe(
      catchError((error) => {
        console.error('Error fetching block data:', error);
        return of(null);
      })
    );

    const bounce$ = this.sendgridService.getBounceByEmail(email).pipe(
      catchError((error) => {
        console.error('Error fetching bounce data:', error);
        return of(null);
      })
    );

    const spam$ = this.sendgridService.getSpamByEmail(email).pipe(
      catchError((error) => {
        console.error('Error fetching spam data:', error);
        return of(null);
      })
    );

    const unsubscribe$ = this.sendgridService.getUnsubscribeByEmail(email).pipe(
      catchError((error) => {
        console.error('Error fetching unsubscribe data:', error);
        return of(null);
      })
    );

    forkJoin([block$, bounce$, spam$, unsubscribe$]).subscribe({
      next: ([blocks, bounces, spams, unsubscribes]) => {
        this.blocks = blocks;
        this.bounces = bounces;
        this.spams = spams;
        this.unsubscribes = unsubscribes;
        if (
          this.blocks.length === 0 &&
          this.bounces.length === 0 &&
          this.spams.length === 0 &&
          this.unsubscribes.length === 0
        ) {
          this.helperService.showSuccessToastr(
            `No suppression data found for ${email}.`
          );
        } else {
          this.suppressionExist = true;
        }
      },
      error: (error) =>
        this.helperService.openErrorModal('Unexpected error', error),
      complete: () => this.helperService.hideSpinner(false),
    });
  }

  getMessage(messageId: string): void {
    this.singleMessageSearch = false;
    this.helperService.showSpinner();
    this.sendgridService.getMessageByMessageId(messageId).subscribe({
      next: (res) => {
        this.messageEvents = res?.events;
        this.helperService.hideSpinner(false);
        this.singleMessageSearch = true;
      },
      error: (error) =>
        this.helperService.openErrorModal(
          'Fail to fetch message content',
          error
        ),
    });
  }

  deleteBounceByEmail(): void {
    const email = this.action
      ? this.form.controls['email'].value.trim()
      : this.email;
    this.helperService.showSpinner();
    this.sendgridService.deleteBounceByEmail(email).subscribe({
      error: (error) =>
        this.helperService.openErrorModal(
          `Fail to delete bounce for ${email}`,
          error
        ),
      complete: () =>
        this.helperService.hideSpinnerCloseModalAndShowToastr(
          `${email} removed from the bounce list successfully!`
        ),
    });
  }

  deleteBlockByEmail(): void {
    const email = this.action
      ? this.form.controls['email'].value.trim()
      : this.email;
    this.helperService.showSpinner();
    this.sendgridService.deleteBlockByEmail(email).subscribe({
      error: (error) =>
        this.helperService.openErrorModal(
          `Fail to delete block for ${email}`,
          error
        ),
      complete: () =>
        this.helperService.hideSpinnerCloseModalAndShowToastr(
          `${email} removed from the block list successfully!`
        ),
    });
  }

  deleteSpamByEmail(): void {
    const email = this.action
      ? this.form.controls['email'].value.trim()
      : this.email;
    this.helperService.showSpinner();
    this.sendgridService.deleteSpamByEmail(email).subscribe({
      error: (error) =>
        this.helperService.openErrorModal(
          `Fail to delete spam for ${email}`,
          error
        ),
      complete: () =>
        this.helperService.hideSpinnerCloseModalAndShowToastr(
          `${email} removed from the spam list successfully!`
        ),
    });
  }

  deleteUnsubscribeByEmail(): void {
    const email = this.action
      ? this.form.controls['email'].value.trim()
      : this.email;
    this.helperService.showSpinner();
    this.sendgridService.deleteUnsubscribeByEmail(email).subscribe({
      error: (error) =>
        this.helperService.openErrorModal(
          `Fail to delete unsubscribe for ${email}`,
          error
        ),
      complete: () =>
        this.helperService.hideSpinnerCloseModalAndShowToastr(
          `${email} removed from the unsubscribe list successfully!`
        ),
    });
  }

  getKeys(event: any): string[] {
    return this.helperService.getKeys(event);
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required]),
    });
  }

  private checkUser() {
    this.subscriptions.push(
      this.sharedDataService.user$.subscribe((user: AuthUserModel) => {
        this.sendgridWrite = this.helperService.checkPermission(
          user,
          this.SENDGRID_WRITE_PERMISSION
        );
      })
    );
  }
}

// rafael.maza+yccrcgsccl@360dialog.com
// contato@midianet.net
