import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { JiraIssueModel } from '../domains/models/jira.model';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root'
})
export class JiraService {

  jiraRoute: string = `${environment.tppCsToolAPI.url}/v1/jira`

  constructor(private httpClient: HttpClient) {}

  getIssue(issueId: string): Observable<JiraIssueModel> {
    return this.httpClient.get<JiraIssueModel>(`${this.jiraRoute}/issue/${issueId}`, { params });
  }

  updateIssue(issueId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(`${this.jiraRoute}/issue/${issueId}`, body);
  }

}
