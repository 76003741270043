import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-two-input-modal',
  templateUrl: './two-input-modal.component.html',
  styleUrls: ['./two-input-modal.component.scss']
})
export class TwoInputModalComponent implements OnInit {

  form: FormGroup;
  title: string = '';
  confirmationText: string = '';
  inputFieldOne: string = '';
  inputFieldTwo: string = '';
  editInputOne: string = '';
  editInputTwo: string = '';

  constructor(
    protected activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  confirm(){
    this.activeModal.close(true);
    this.editInputOne = this.form.controls['editValueOne'].value;
    this.editInputTwo = this.form.controls['editValueTwo'].value;
  }

  close(){
    this.activeModal.dismiss();
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      editValueOne: new FormControl(this.editInputOne ? this.editInputOne : '', [Validators.required]),
      editValueTwo: new FormControl(this.editInputTwo ? this.editInputTwo : '', [Validators.required])
    });
  }

}
