import { HelperService } from 'src/app/core/services/helper/helper.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {
  @Input() tooltip: string;
  @Input() value: string;

  constructor(private helperService: HelperService) {}

  copyText(value) {
    this.helperService.copyText(value);
  }
}
