import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Channel } from 'src/app/core/domains/models/channels.module';
import { CsToolApiResponseModel } from 'src/app/core/domains/models/cs-tool-api-response.model';
import { WabaInfo } from 'src/app/core/domains/models/waba-info.model';
import { WabaUsersModel } from 'src/app/core/domains/models/waba-users.model';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { OboToSharedService } from 'src/app/core/services/obo-to-shared/obo-to-shared.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit, OnDestroy {
  @Output() nextStep = new EventEmitter<any>();
  @Input() channel: Channel;

  private mfaSubscription: Subscription;

  form: FormGroup;
  setPaymentMethodForm: FormGroup;

  oldWaba: WabaInfo = {};
  newWaba: WabaInfo = {};

  wabaUsers: WabaUsersModel[] = [];

  containsWabaDev: boolean = false;
  paymentSet: boolean = false;

  numberOfChannels: number = 0;

  mfa: string = '';
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private csToolApiService: CsToolApiService,
    private sharedDataService: SharedDataService,
    private oboToSharedService: OboToSharedService
  ) {
    this.generateForm();
  }

  ngOnInit(): void {
    this.getWabaInfo(this.channel?.waba_account?.external_id);
    this.subscribeToSharedData();
  }

  ngOnDestroy() {
    this.mfaSubscription.unsubscribe();
  }

  isObjectEmpty(obj: any): boolean {
    return this.helperService.isObjectEmpty(obj);
  }

  checkWaba(): void {
    this.newWaba = {};
    this.wabaUsers = [];
    this.containsWabaDev = false;
    this.checkWabaDev();
  }

  startMigration(): void {
    this.errorMessage = '';
    this.helperService.showSpinner();
    const body = {
      agent_responsible: localStorage.getItem('hub_username'),
      phone_number: this.channel?.setup_info?.phone_number,
      host_type: this.channel?.integration?.hosting_platform_type,
      cc: this.form.controls['cc'].value.trim(),
      current_waba_id: this.channel?.waba_account?.external_id,
      current_namespace: this.channel?.waba_account?.namespace,
      current_phone_number_id: this.channel?.external_id,
      new_waba_id: this.form.controls['newWabaId'].value.trim(),
      pin: this.mfa
    };
    this.csToolApiService.startMigration(body).subscribe({
      next: (res) => {
        if(res.success) {
          this.oboToSharedService.setPayload(res.migration);
          this.nextStep.emit('stepOne');
        } else {
          this.errorMessage = this.oboToSharedService.parseErrorMessage(res);
        }
        this.helperService.hideSpinner(false);
      },
       error: (err) => {
        this.helperService.hideSpinner(false);
        this.helperService.showErrorToastr('Failed to start migration. Please post the issue to cs-tool channel.')
       }
    });
  }

  setPaymentMethod(): void {
    this.helperService.showSpinner();
    const body = {
      creditId: this.setPaymentMethodForm.controls['creditId'].value,
      wabaCurrency: this.setPaymentMethodForm.controls['wabaCurrency'].value,
    };
    this.csToolApiService
    .setPaymentMethod(this.form.controls['newWabaId'].value, body)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        if (res.data?.response?.error) {
          this.errorMessage = "Error:" + JSON.stringify(res.data.response);
        } else {
          this.helperService.showSuccessToastr("Payment method Set successfully!");
          this.getWabaInfo(this.form.controls['newWabaId'].value, false);
          this.paymentSet = true;
        }
      },
      error: (err) => {
        this.errorMessage = "Error:" + err;
        this.helperService.hideSpinner(false);
      },
      complete: () => {
        this.getWabaInfo(this.form.controls['newWabaId'].value, false);
      }
    });
  }

  isNewWabaValid(): boolean {
    return this.form.get('newWabaId').valid;
  }

  private getWabaInfo(wabaId: string, oldWaba: boolean = true): void {
    this.errorMessage = '';
    this.helperService.showSpinner();
    this.csToolApiService
    .getWabaInfo(wabaId)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        this.helperService.hideSpinner(false);
        if (res.data?.waba?.error) {
          this.errorMessage = JSON.stringify(res.data?.waba?.error);
        } else {
          if (oldWaba) {
            this.oldWaba = res?.data?.waba;
          } else {
            this.newWaba = res?.data?.waba;
            this.paymentSet = res?.data?.waba.primary_funding_id != null && res?.data?.waba.primary_funding_id != "" && res?.data?.waba.primary_funding_id != "0" && res?.data?.waba.currency != null && res?.data?.waba.currency != '';
          }
          this.helperService.hideSpinner(false);
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to get WABA Info.', err);
      },
    });
  }

  private checkWabaDev() {
    this.helperService.showSpinner();
    this.csToolApiService
    .getWabaUsers(this.form.controls['newWabaId'].value)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        if (res.data?.waba?.error) {
          this.helperService.openErrorModal('Failed to get WABA Users.', res.data?.waba?.error);
        } else {
          this.wabaUsers = res.data.waba.data;
          this.containsWabaDev = this.wabaUsers.some(item => item.id === "112511530302197");
          if (this.containsWabaDev) {
            this.getWabaInfo(this.form.controls['newWabaId'].value, false);
          } else {
            this.assignWabaDev();
          }
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to get WABA Users.', err);
      },
    });
  }

  private assignWabaDev(): void {
    this.helperService.showSpinner();
    this.csToolApiService
    .assignWabaDev(this.form.controls['newWabaId'].value)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        if (res.data?.waba?.error) {
          this.helperService.openErrorModal('Failed to assign DEV users.', res.data?.waba?.error);
        } else {
          this.wabaUsers.push({
            id: '112511530302197',
            name: "waba_dev",
            tasks: ["MANAGE"]
          });
          this.containsWabaDev = true;
          this.getWabaInfo(this.form.controls['newWabaId'].value, false);
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to Assign Waba DEV.', err);
      },
    });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      newWabaId: new FormControl('', [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(18),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      ]),
      cc: new FormControl('', [Validators.required]),
    });

    this.setPaymentMethodForm = this.formBuilder.group({
      creditId: new FormControl("3214250008690288", [Validators.required]),
      wabaCurrency: new FormControl("EUR", [Validators.required]),
    });
  }

  private subscribeToSharedData() {
    this.mfaSubscription = this.sharedDataService.mfaNotification$.subscribe(
      (payload: any) => {
        if (payload.isCompleted) {
          this.mfa = String(payload.payload);
        }
      }
    );
  }

}
