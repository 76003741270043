import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, Subject } from 'rxjs';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss']
})
export class SimpleSearchComponent implements OnInit, OnDestroy {

  @Output() searchParameters = new EventEmitter<any>();
  @Input() value: string = '';
  @Input() placeholder: string = 'Search...';

  private searchInputSubject$ = new Subject<any>();

  constructor() { }

  ngOnInit(): void {
    this.searchInputSubject$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchInput) => {
      this.searchParameters.emit(searchInput);
    });
  }

  ngOnDestroy(): void {
    this.searchInputSubject$.unsubscribe();
  }

  onSerach(searchInput) {
    this.searchInputSubject$.next(searchInput);
  }

}
