import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface ButtonOptions {
  color?: string;
}

@Component({
  selector: 'app-ng-bootstrap-dropdown',
  templateUrl: './ng-bootstrap-dropdown.component.html',
  styleUrls: ['./ng-bootstrap-dropdown.component.scss']
})

export class NgBootstrapDropdownComponent implements OnChanges {

  @Input() dropdownItems: Array<string>;
  @Input() selectedItem: string;
  @Input() defaultDropdownOption: string;
  @Input() disabled = false;
  @Output() dropdownSelection = new EventEmitter<string>();
  @Input() buttonOptions?: ButtonOptions;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && !changes['selectedItem'].isFirstChange()) {
      const previousValue = changes['selectedItem'].previousValue;
      const currentValue = changes['selectedItem'].currentValue;

      if (previousValue !== currentValue) {
        this.dropdownSelection.emit(this.selectedItem);
      }
    }
  }

  dropdownItemSelected($event: MouseEvent) {
    this.selectedItem = ($event.target as HTMLButtonElement).value;
    this.dropdownSelection.emit(this.selectedItem);
  }

}
