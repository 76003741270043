import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { FraudScore } from '../../domains/models/fraud-score.model';

@Injectable({
  providedIn: 'root'
})
export class DatabricksService {

  databricksRoute: string = `${environment.tppCsToolAPI.url}/v1/databricks`

  constructor(private httpClient: HttpClient) {}

  getFraudScore(channelId: string): Observable<FraudScore> {
    return this.httpClient.get<FraudScore>(`${this.databricksRoute}/get-fraud-score/${channelId}`);
  }

}
