<div ngbDropdown class="d-inline-block ml-auto dropdown-container">
  <button type="button" class="btn btn-outline-dark" ngbDropdownToggle>
    {{ selectedItem ? selectedItem : defaultDropdownOption }}
  </button>
  <div ngbDropdownMenu>
    <button type="button" ngbDropdownItem disabled *ngIf="defaultDropdownOption">
      {{ defaultDropdownOption }}
    </button>
    <button
      *ngFor="let dropdownItem of dropdownItems"
      ngbDropdownItem
      type="button"
      (click)="dropdownItemSelected($event)"
      [value]="dropdownItem"
    >
      {{ dropdownItem }}
    </button>
  </div>
</div>
