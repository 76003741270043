import { Flows } from './../../domains/models/flows.module';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterPartnerTypeEnum, FilterTypeEnum } from '../../domains/enums/filter-type.enum';
import { ChannelUsers } from '../../domains/models/channel-users.module';
import {
  ApiKeys,
  BillingInfo,
  Channels,
  Partner,
} from '../../domains/models/channels.module';
import { ClientBalance } from '../../domains/models/client-balance.module';
import { Comments } from '../../domains/models/comments.module';
import { LanguageAndTimezone } from '../../domains/models/languiage-and-timezone.modal';
import { PartnerClients } from '../../domains/models/partner-clients.module';
import { Partners } from '../../domains/models/partners.module';
import { ProfileSettings } from '../../domains/models/profile-settings.module';
import { RequestQueryModel } from '../../domains/models/request-query.model';
import { Templates } from '../../domains/models/templates.module';
import { UserToken } from '../../domains/models/user-token.module';
import { CommerceSettings } from '../../domains/models/commerce-settings';
import { PaymentConnections } from '../../domains/models/payment-connections';
import { PartnerPublicData } from '../../domains/models/partner-public-data.module';
import { PartnerChangeRequests } from '../../domains/models/partner-change-requests';
import { PartnerPaymentPlan } from '../../domains/models/partner-payment-plan.module';
import { PbsInvoices } from '../../domains/models/pbs-invoices.module';
import { BillingDetails } from '../../domains/models/billing-details.module';
import { PartnerInvoices } from '../../domains/models/partner-invoices.module';
import { PartnerPaymentDetails } from '../../domains/models/partner-payment-details.module';
import { PartnerBillingDetails } from '../../domains/models/partner-billing-details';
import { PartnerAutoRechargeSettings } from '../../domains/models/partner-auto-recharge-settings.module';
import { RechargeSettingsPBS } from '../../domains/models/recharge-settings-pbs.model';

@Injectable({
  providedIn: 'root',
})
export class HubService {
  constructor(private httpClient: HttpClient) {
    this.target = localStorage.getItem('loginType') == 'new' ? `${environment.tppCsToolAPI.url}/v1/hub` : environment.hubApi;
  }

  target: string = '';

  loginUserWithEmailAndPassowrd(
    email: string,
    password: string
  ): Observable<UserToken> {
    const body = {
      username: email,
      password: password,
    };
    return this.httpClient.post<UserToken>(
      `${environment.hubApi}/v1/token`,
      body
    );
  }

  getChannels(
    type: string,
    parameter: string,
    reset = false,
    requestQuery?: RequestQueryModel
  ): Observable<Channels> {
    const params = this.formChannelHttpParams(
      type,
      parameter,
      reset,
      requestQuery
    );

    return this.httpClient.get<Channels>(`${this.target}/v1/channels`, {
      params,
    });
  }

  checkPartnerPermission(partnerId: string, clientId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: "true" };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/clients-shared-numbers`,
      { params }
    );
  }


  getNumberByApiKey(apiKey: string): Observable<any> {
    const headers = new HttpHeaders({ 'D360-API-KEY': apiKey });
    return this.httpClient.get(
      `${environment.hubWabaApi}/v1/configs/phone_number`,
      {
        headers: headers,
      }
    );
  }

  getByStackId(requestQuery?: RequestQueryModel): Observable<Channels> {
    let offset = 0;
    const fromObject: { [index: string]: string } = {
      offset: offset.toString(),
      limit: requestQuery.size.toString(),
      sort: '-modified_at',
    };
    fromObject[
      'filters'
    ] = `{${`"stack_id" : {"like": "${requestQuery['filters']['stackId']}"}`.replace(
      /,*$/,
      ''
    )}}`;
    if (requestQuery.page != 1) {
      offset = (requestQuery.page - 1) * 15;
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Channels>(
      `${this.target}/v1/partners/waba_setup`,
      { params }
    );
  }

  getPaymentConnection(
    channelId: string,
    offset: string,
    size: number
  ): Observable<PaymentConnections> {
    const fromObject: { [index: string]: string } = {
      limit: size.toString(),
      offset: offset,
      reset: 'true'
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PaymentConnections>(
      `${this.target}/v1/invoicing/channels/${channelId}/payment_connections`,
      { params }
    );
  }

  getPbsInvoices(
    partnerId: string,
    clientId: string,
    page: number
  ): Observable<PbsInvoices[]> {
    const fromObject: { [index: string]: string } = {
      page: page.toString()
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PbsInvoices[]>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/invoices`, {params}
    );
  }

  getPbsBillingDetails(
    partnerId: string,
    clientId: string
  ): Observable<BillingDetails> {
    return this.httpClient.get<BillingDetails>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/info/billing_data`
    );
  }

  getBillingInfo(partnerId: string, clientId: string, reset: boolean): Observable<BillingInfo> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<BillingInfo>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/info/payment`,
      { params }
    );
  }

  getClientBalance(
    partnerId: string,
    clientId: string
  ): Observable<ClientBalance> {
    return this.httpClient.get<ClientBalance>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/info/balance`
    );
  }

  getClientProjects(
    partnerId: string,
    clientId: string
  ): Observable<any> {
    const fromObject: { [index: string]: string } = {reset: 'true'};
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects`, { params }
    );
  }

  getClientAccounts(
    partnerId: string,
    clientId: string
  ): Observable<any> {
    const fromObject: { [index: string]: string } = {reset: 'true'};
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/waba_accounts`, { params }
    );
  }

  getChannelBalance(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    fromYear: string,
    reset: boolean
  ): Observable<ClientBalance> {
    const fromObject: { [index: string]: string } = {
      from_year: fromYear,
      reset: reset.toString()
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<ClientBalance>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/info/balance`,
      { params }
    );
  }

  getCommerceSettings(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    reset: boolean
  ): Observable<CommerceSettings> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    const url = `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/whatsapp_commerce_settings`;
    return this.httpClient.get<CommerceSettings>(url, { params });
  }

  updateCommerceSettings(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    commerceSettings: {}
  ): Observable<any> {
    const body = commerceSettings;
    const url = `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/whatsapp_commerce_settings`;
    return this.httpClient.post<any>(url, body);
  }

  migrateStackToOnPremise(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string
  ): Observable<any> {
    return this.httpClient.post<any>(`${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/migrate_to_on_premise`, {});
  }

  migrateStackToCloudApi(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string
  ): Observable<any> {
    return this.httpClient.post<any>(`${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/migrate_to_cloud_api`, {});
  }

  getMfa(stackId): Observable<any> {
    return this.httpClient.get<any>(`${this.target}/v1/pin/${stackId}`);
  }

  syncWaba(
    partnerId: string,
    clientId: string,
    wabaId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaId}/control/synchronize_status`,
      {}
    );
  }

  patchWaba(
    partnerId: string,
    clientId: string,
    wabaId: string,
    field: string,
    value: string
  ): Observable<any> {
    const body = {};
    body[field] = value;
    const url = `${this.target}/v1/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaId}`;
    return this.httpClient.patch<any>(url, body);
  }

  syncChannel(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/update_status`,
      body
    );
  }

  reSubmitChannel(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/submit`,
      {}
    );
  }

  editMaxChannels(
    partnerId: string,
    clientId: string,
    maxChannelValue: number
  ): Observable<any> {
    const body = { max_channels: maxChannelValue };
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/control/max_channels`,
      body
    );
  }

  editChannelValue(
    partnerId: string,
    channelId: string,
    editValue,
    fieldName: string
  ): Observable<any> {
    const body = {};
    body[fieldName] = editValue;
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}`,
      body
    );
  }

  editPlan(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/set_price_tier`,
      body
    );
  }

  deleteChannel(partnerId: string, clientId: string, projectId: string, channelId: string): Observable<any> {
    return this.httpClient.delete(`${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}`);
  }

  setTerminationDate(
    partnerId: string,
    channelId: string,
    terminationDate: string
  ): Observable<any> {
    const body = { finish_date: terminationDate };
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}/control/update_billing_finish_date`,
      body
    );
  }

  cancelSubscription(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/cancellation_request`,
      {}
    );
  }

  fixCapiInternallCallbacker(
    partnerId: string,
    clientId: string,
    channelId: string,
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/channels/${channelId}/register_migrated_number`,
      {}
    );
  }

  refundBalance(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/issue_refund`,
      {}
    );
  }

  updateTieredPricing(
    partnerId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/settings/tiered_pricing`,
      body
    );
  }

  getPricingPlans(partnerId: string): Observable<any> {
    return this.httpClient.get(`${this.target}/v1/invoicing/partners/${partnerId}/pricing_plans`);
  }

  getPricingVersions(versionId: string): Observable<any> {
    return this.httpClient.get(`${this.target}/v1/invoicing/pricing_plans/${versionId}/versions`);
  }

  updateDiscountNumbers(
    partnerId: string,
    body: {}
    ): Observable<any> {
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/settings/discounted_numbers`,
      body
    );
  }

  updateBillingFinishDate(
    partnerId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}/control/update_billing_finish_date`,
      body
    );
  }

  createPaymentConnection(
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/invoicing/channels/${channelId}/payment_connections`,
      body
    );
  }

  startBilling(
    partnerId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}/control/update_billing_start_date`,
      body
    );
  }

  setPremium(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/set_premium`,
      {}
    );
  }

  deRegisterLS(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    target: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/${target}`,
      body
    );
  }

  flagForReOnboarding(
    channelId: string,
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/channels/${channelId}/flag_for_reonboarding`,
      {}
    );
  }

  enableMfa(appId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/internal/v1/app_integrations/${appId}/control/set_2fa`,
      {}
    );
  }

  editPrepaidValue(
    partnerId: string,
    clientId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/control/update_billing_data`,
      body
    );
  }

  cancleTermination(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/revoke_cancellation`,
      {}
    );
  }

  getComments(
    partnerId: string,
    channelId: string,
    newestFirst: boolean,
    filterForThisChannel: boolean
  ): Observable<Comments> {
    const fromObject: { [index: string]: string } = {
      sort: newestFirst ? '-created_at' : 'created_at',
      offset: '0',
      limit: '1000'
    };
    if(filterForThisChannel) {
      fromObject['filters'] = `{"channel_id":{"eq":"${channelId}"}}`
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Comments>(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}/comments`,
      { params }
    );
  }

  addComment(
    comment: string,
    partnerId: string,
    channelId: string
  ): Observable<any> {
    const body = { content: comment };
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}/comments`,
      body
    );
  }

  setChannelUnverified(partnerId: string, channelId: string): Observable<any> {
    const body = { status: 'unverified' };
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}`,
      body
    );
  }

  setChannelVerified(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    cert: string
  ): Observable<any> {
    const body = { certificate: cert };
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/set_verified`,
      body
    );
  }

  getProfileSettings(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    reset: boolean
  ): Observable<ProfileSettings> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<ProfileSettings>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/profile_settings`,
      { params }
    );
  }

  updateProfileLogo(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    file
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/upload_profile_photo`,
      file
    );
  }
  editProfileSettings(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    businessProfile
  ): Observable<any> {
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/profile_settings`,
      businessProfile
    );
  }

  toggleAllowTemplateMessaging(
    isAllowed: boolean,
    channelId: string
  ): Observable<any> {
    let body: {
      channels_to_block_sending?: string[];
      channels_to_allow_sending?: string[];
    };
    let url: string;
    if (isAllowed) {
      url = `${this.target}/v1/template_messaging/block`;
      body = { channels_to_block_sending: [channelId] };
    } else {
      url = `${this.target}/v1/template_messaging/allow`;
      body = { channels_to_allow_sending: [channelId] };
    }
    return this.httpClient.post(url, body);
  }

  toggleSuspicious(
    partnerId: string,
    clientId: string,
    suspicious: boolean
  ): Observable<any> {
    const body = {
      suspicious: suspicious,
      reason: ""
    };
    return this.httpClient.patch(`${this.target}/v1/partners/${partnerId}/clients/${clientId}/control/suspicious`, body);
  }

  fixFinalizingIntegration(
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/waba_trigger_registration/${appId}`,
      body
    );
  }

  toggleCtwa(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    url: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/${url}`,
      {}
    );
  }

  toggleChatSupport(
    partnerId: string,
    clientId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/control/enabled_for_chat_support`,
      body
    );
  }

  toggleALlowMessagesWithNegativeBalance(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    target: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/${target}`,
      {}
    );
  }

  reRegisterCloudAPINumber(
    appId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/waba_trigger_registration/${appId}`,
      {}
    );
  }

  finishRegistration(appId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/waba_finish_registration/${appId}`,
      {}
    );
  }

  setIntegrationAvailability(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    integrationState: boolean
  ): Observable<any> {
    const body = { enabled: integrationState };
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/set_availability`,
      body
    );
  }

  deleteStack(appId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/internal/v1/app_integrations/${appId}/control/remove_stack`,
      {}
    );
  }

  createStack(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/trigger_setup`,
      body
    );
  }

  sendNotification(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/user-notifications/send_adhoc_notifications`,
      body
    );
  }

  addBalanceToPartner(
    partnerId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/pbs/add_funds`,
      body
    );
  }

  addFundsToChannel(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/add_funds`,
      body
    );
  }

  getUsers(
    partnerId: string,
    clientId: string,
    userEmail: string,
    reset: boolean
  ): Observable<ChannelUsers> {
    const fromObject: { [index: string]: string } = {
      reset: reset.toString(),
      sort: 'integration.parameters.app_name',
    };
    if (userEmail) {
      fromObject['filters'] = `{"email":{"like":"${userEmail}"}}`;
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<ChannelUsers>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/users`,
      { params }
    );
  }

  addNewUser(
    partnerId: string,
    clientId: string,
    userName: string,
    userEmail: string
  ): Observable<any> {
    const body = {
      name: userName,
      email: userEmail,
    };
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/users`,
      body
    );
  }

  deleteUser(userEmail: string): Observable<any> {
    const options = { body: { email: userEmail } };
    return this.httpClient.delete(`${this.target}/v1/users`, options);
  }

  getChannelTemplates(
    partnerId: string,
    wabaId: string,
    templateName: string
  ): Observable<Templates> {
    const fromObject: { [index: string]: string } = {
      sort: 'business_templates.name',
    };
    if (templateName) {
      fromObject[
        'filters'
      ] = `{"business_templates.name":{"like":"${templateName}"}}`;
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Templates>(
      `${this.target}/v1/partners/${partnerId}/waba_accounts/${wabaId}/waba_templates`,
      { params }
    );
  }

  synchronizeTemplates(partnerId: string, wabaId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/waba_accounts/${wabaId}/waba_templates/import`,
      {}
    );
  }

  portNumber(
    partnerId: string,
    clientId: string,
    wabaAccId: string,
    phoneNumber: number
  ): Observable<any> {
    const body = {
      waba_account_id: wabaAccId,
      phone_number: phoneNumber
    };
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/migrate_channel`,
      body
    );
  }

  getPartners(
    reset: boolean,
    offset: string,
    requestQuery?: RequestQueryModel
  ): Observable<Partners> {
    let fromObject: { [index: string]: string };
    fromObject = {
      offset: offset,
      limit: requestQuery.size.toString(),
      reset: reset.toString(),
    };
    if (requestQuery.filters) {
      let filterString: string = '';
      for (const [key, value] of Object.entries(requestQuery.filters)) {
        if (value != null) {
          if (key == 'partnerId') {
            filterString += `"${FilterPartnerTypeEnum[key]}":"${value}",`;
          } else {
            filterString += `"${FilterPartnerTypeEnum[key]}" : {"like": "${value}"},`;
          }
        }
      }
      if (filterString) {
        fromObject['filters'] = `{${filterString.replace(/,*$/, '')}}`;
      }
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Partners>(`${this.target}/v1/partners`, {
      params,
    });
  }

  getPartnerPublicData(partnerId: string): Observable<PartnerPublicData> {
    return this.httpClient.get<PartnerPublicData>(
      `${this.target}/api/v2/partners/${partnerId}`
    );
  }

  getPartnerPaymentPlan(partnerId: string): Observable<PartnerPaymentPlan[]> {
    return this.httpClient.get<PartnerPaymentPlan[]>(
      `${this.target}/v1/partners/${partnerId}/payment_plan`
    );
  }

  addPartner(body: {}): Observable<any> {
    return this.httpClient.post<any>(`${this.target}/v1/partners`, body);
  }

  getPartnerDetails(
    partnerId: string,
    reset: boolean = false
  ): Observable<Partner> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Partner>(
      `${this.target}/v1/partners/${partnerId}`,
      { params }
    );
  }

  getPartnerInvoices(
    page: string,
    partnerId: string,
  ): Observable<PartnerInvoices> {
    const fromObject: { [index: string]: string } = {
      page: page
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PartnerInvoices>(
      `${this.target}/v1/partners/${partnerId}/pbs/invoices`,
      {params}
    );
  }

  getPartnerPaymentDetails(
    partnerId: string
  ): Observable<PartnerPaymentDetails> {
    return this.httpClient.get<PartnerPaymentDetails>(
      `${this.target}/v1/partners/${partnerId}/pbs/payment_methods`
    );
  }

  getPartnerBillingDetails(
    partnerId: string
  ): Observable<PartnerBillingDetails> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PartnerBillingDetails>(
      `${this.target}/v1/partners/${partnerId}/pbs/billing_data`, { params }
    );
  }

   updatePartnerBillingDetails(
    partnerId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/pbs/billing_data`, body
    );
  }

   setDataLocalization(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/enable_local_storage`, body
    );
  }

  getPaymentAutoRechargeSettings(
    partnerId: string
  ): Observable<PartnerAutoRechargeSettings> {
    return this.httpClient.get<PartnerAutoRechargeSettings>(
      `${this.target}/v1/partners/${partnerId}/pbs/auto_recharge_settings`
    );
  }

  getChannelAutoRechargeSettingsPBS(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string
  ): Observable<RechargeSettingsPBS> {
    return this.httpClient.get<RechargeSettingsPBS>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/auto_recharge_settings`
    );
  }

  getPartnerPrepaidBalance(partnerId: string ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/api/v2/partners/${partnerId}/balance`,
      { params }
    );
  }

  updatePartner(partnerId: string, body: {}): Observable<any> {
    return this.httpClient.patch<any>(
      `${this.target}/v1/partners/${partnerId}`,
      body
    );
  }

  setPartnerCreditLine(partnerId: string, body: {}): Observable<any> {
    return this.httpClient.patch<any>(
      `${this.target}/v1/partners/${partnerId}/settings/credit_line`,
      body
    );
  }

  pahtchBlockNewSubmission(partnerId: string, body: {}): Observable<any> {
    return this.httpClient.patch<any>(
      `${this.target}/v1/partners/${partnerId}/block_new_submission`,
      body
    );
  }

  updatePartnerLogo(partnerId: string, file): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/control/upload_logo`,
      file
    );
  }

  enableOutboundMessages(partnerId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/outbound_messaging/partners/${partnerId}/allow`,
      {}
    );
  }

  allowOutboundMessages(partnerId: string, clientId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/outbound_messaging/partners/${partnerId}/clients/${clientId}/allow`,
      {}
    );
  }

  setPaymentPlanForPartner(partnerId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/control/payment_plan`,
      body
    );
  }

  patchAccountSharingSettings(partnerId: string, body: {}): Observable<any> {
    return this.httpClient.patch(
      `${this.target}/v1/partners/${partnerId}/settings/account_sharing`,
      body
    );
  }

  disableOutboundMessages(partnerId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/outbound_messaging/partners/${partnerId}/block`,
      {}
    );
  }


  generatePaymentLinkPartner(partnerId: string, invoiceId: string ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/partners/${partnerId}/pbs/invoices/${invoiceId}/payment-link`,
      { params }
    );
  }

  generatePaymentLinkClient(partnerId: string, clientId: string, invoiceId: string ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/invoices/${invoiceId}/payment-link`,
      { params }
    );
  }

  partnerChangeRequest(
    partnerId: string,
    clientId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/control/migrate_to_partner`,
      body
    );
  }

  getPartnerUsers(
    offset: string,
    size: number,
    partnerId: string,
    reset: boolean,
    user: string,
    sort: string
  ): Observable<ChannelUsers> {
    const fromObject: { [index: string]: string } = {
      sort: sort,
      limit: size.toString(),
      reset: reset.toString(),
      offset: offset,
    };
    if (user) {
      fromObject['filters'] = `{"name":{"like":"${user}"}}`;
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<ChannelUsers>(
      `${this.target}/v1/partners/${partnerId}/users`,
      { params }
    );
  }

  addPartnerUser(
    partnerId: string,
    userName: string,
    userEmail: string
  ): Observable<any> {
    const body = {
      name: userName,
      email: userEmail,
    };
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/users`,
      body
    );
  }

  getPartnerClients(
    offset: string,
    size: number,
    partnerId: string,
    reset: boolean,
    client: string
  ): Observable<PartnerClients> {
    const fromObject: { [index: string]: string } = {
      sort: '-modified_at',
      limit: size.toString(),
      reset: reset.toString(),
      offset: offset,
    };
    if (client) {
      fromObject['filters'] = `{"name":{"like":"${client}"}}`;
    }
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PartnerClients>(
      `${this.target}/v1/partners/${partnerId}/clients`,
      { params }
    );
  }

  getPartnersChannels(
    fromObject: {}
  ): Observable<Channels> {
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Channels>(`${this.target}/v1/channels`, { params });
  }

  listPartnerChannels(
    partnerId: string,
    fromObject: {}
  ): Observable<Channels> {
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Channels>(`${this.target}/v1/partners/${partnerId}/channels`, { params });
  }

  addClient(partnerId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/v1/partners/${partnerId}/clients`,
      body
    );
  }

  getPartnerChangeRequests(
    partnerId: string,
    reset: boolean
  ): Observable<PartnerChangeRequests> {
    const fromObject: { [index: string]: string } = {
      reset: reset.toString(),
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PartnerChangeRequests>(
      `${this.target}/v1/partners/${partnerId}/partner_change_requests`,
      { params }
    );
  }

  getPartnerChannels(partnerId: string, phoneNumber: string): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
      filters: `{"setup_info.phone_number":{"eq":"${phoneNumber}"}}`
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/partners/${partnerId}/channels`,
      { params }
    );
  }

  getIfPartnerBlocked(partnerId: string): Observable<any> {
    const fromObject: { [index: string]: string } = {
      filters: `{"type": "outbound_messaging_changed"}`,
      reset: 'true'
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/v1/activity_logs/partners/${partnerId}`,
      { params }
    );
  }

  getClientPcrInfo(partnerId: string, clienId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.target}/v1/partners/${partnerId}/clients/${clienId}/partner_change_requests`);
  }

  acceptPcr(id: string, clientId: string, partnerId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/partners/${partnerId}/clients/${clientId}/partner_change_requests/${id}/approve`,
      {}
    );
  }

  rejectPcr(
    id: string,
    clientId: string,
    partnerId: string,
    rejectReason: string
  ): Observable<any> {
    const body = {
      reject_reason: rejectReason,
    };
    return this.httpClient.post(
      `${this.target}/partners/${partnerId}/clients/${clientId}/partner_change_requests/${id}/reject`,
      body
    );
  }

  getLanguagesAndTimezones(partnerId: string): Observable<LanguageAndTimezone> {
    return this.httpClient.get<LanguageAndTimezone>(
      `${this.target}/v1/partners/${partnerId}/configs/filter_options`
    );
  }

  getBusinessAccount(
    partnerId: string,
    businessAccountId: string
  ): Observable<LanguageAndTimezone> {
    return this.httpClient.get<LanguageAndTimezone>(
      `${this.target}/v1/partners/${partnerId}/info/facebook_account/${businessAccountId}`
    );
  }

  getChannel(partnerId: string, channelId: string): Observable<any> {
    return this.httpClient.get(
      `${this.target}/v1/partners/${partnerId}/channels/${channelId}`
    );
  }

  getDeletedChannel(clientId: string, partnerId: string): Observable<any> {
    return this.httpClient.get(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/deleted_channels`
    );
  }

  refundUnusedFunds(clientId: string, partnerId: string): Observable<any> {
    return this.httpClient.post(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/refund_unused_funds`, {}
    );
  }

  removeViolation(
    partnerId: string,
    clientId: string,
    violationId: String
  ): Observable<any> {
    return this.httpClient.delete(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/waba_accounts/violations/${violationId}`
    );
  }

  getApiKeys(appId: string, partnerId: string): Observable<ApiKeys> {
    return this.httpClient.get<ApiKeys>(
      `${this.target}/v1/partners/${partnerId}/apps/${appId}/api_keys`
    );
  }

  getInvoicePdfFile(partnerId: string, clientId: string, invoiceId: string): Observable<any> {
    return this.httpClient.get(
      `${this.target}/v1/partners/${partnerId}/clients/${clientId}/invoice/${invoiceId}/pdf`,
      { responseType: 'blob' }
    );
  }
  downloadPartnerInvoice(partnerId: string, invoiceId: string): Observable<any> {
    return this.httpClient.get(
      `${this.target}/v1/partners/${partnerId}/pbs/invoices/${invoiceId}/pdf`,
      { responseType: 'blob' }
    );
  }

  getAllFlows(
    partnerId: string,
    wabaId: string
  ): Observable<Flows> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Flows>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows`,
      { params }
    );
  }

  getFlowDetails(
    partnerId: string,
    wabaId: string,
    flowId: string,
  ): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows/${flowId}`,
      { params }
    );
  }

  getFlowAssets(
    partnerId: string,
    wabaId: string,
    flowId: string,
  ): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows/${flowId}/assets`,
      { params }
    );
  }

  createFlow(
    partnerId: string,
    wabaId: string,
    body: {},
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows`,
      body
    );
  }

  updateFlow(
    partnerId: string,
    wabaId: string,
    flowId: string,
    body: {},
  ): Observable<any> {
    return this.httpClient.patch<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows/${flowId}`,
      body
    );
  }

  deleteFlow(
    partnerId: string,
    wabaId: string,
    flowId: string,
  ): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows/${flowId}`
    );
  }

  publishFlow(
    partnerId: string,
    wabaId: string,
    flowId: string,
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows/${flowId}/publish`,
      {}
    );
  }

  deprecateFlow(
    partnerId: string,
    wabaId: string,
    flowId: string,
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.target}/api/v2/partners/${partnerId}/waba_accounts/${wabaId}/flows/${flowId}/deprecate`,
      {}
    );
  }

  getDataFromUrl(url: string): Observable<any> {
    return this.httpClient.get<any>(url);
  }

  private formChannelHttpParams(
    type: string,
    parameter: string,
    reset: boolean,
    requestQuery?: RequestQueryModel
  ): HttpParams {
    let fromObject: { [index: string]: string };
    if (type == 'channels') {
      let offset = 0;
      if (requestQuery.page != 1) {
        offset = (requestQuery.page - 1) * 15;
      }
      fromObject = {
        offset: offset.toString(),
        limit: requestQuery.size.toString(),
        sort: '-modified_at',
        reset: reset.toString(),
      };
      if (requestQuery.filters) {
        let filterString: string = '';
        for (const [key, value] of Object.entries(requestQuery.filters)) {
          if (value != null) {
            if (key == 'appId' || key == 'channelId') {
              filterString += `"${FilterTypeEnum[key]}":"${value}",`;
            } else {
              filterString += `"${FilterTypeEnum[key]}" : {"like": "${value}"},`;
            }
          }
        }
        if (filterString) {
          fromObject['filters'] = `{${filterString.replace(/,*$/, '')}}`;
        }
      }
    } else if (type == 'number') {
      fromObject = { filters: `{"client.id":{"eq":"${parameter}"}}` };
    } else if (type == 'id') {
      fromObject = { filters: `{"id":{"eq":"${parameter}"}}` };
    } else if (type == 'details') {
      fromObject = {
        limit: '1',
        sort: 'integration.parameters.app_name',
        filters: `{"setup_info.phone_number":{"eq":"${parameter}"}}`,
        reset: reset.toString(),
      };
    }
    return new HttpParams({ fromObject });
  }
}
