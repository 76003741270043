import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class PbsService {
  pbsRoute: string = `${environment.tppCsToolAPI.url}/pbs`;

  constructor(private httpClient: HttpClient) {}

  triggerPayment(invoiceId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/pay`,
      {}
    );
  }

  refundFullInvoice(invoiceId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/refund`,
      {}
    );
  }

  refundPartialInvoice(invoiceId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/refund/partial`,
      body
    );
  }
}
