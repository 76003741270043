import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDiffinSeconds',
})
export class DateDiffinSecondsPipe implements PipeTransform {
  transform(seconds: number): Date {
    return new Date(new Date().getTime() - seconds * 1000);
  }
}
