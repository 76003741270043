<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Meta business account info</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-10">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Meta business ID</span>
          </div>
          <input [(ngModel)]="facebookId" type="text" class="form-control" />
        </div>
      </div>
      <div class="col-2">
        <button
          class="bi bi-backspace btn btn-outline-dark"
          (click)="clear()"
          ngbTooltip="Clear"
        ></button>
      </div>
      <ng-container *ngIf="preview">
        <hr />
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <h5>Meta business Preview</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="table">
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{{ preview.id }}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{{ preview.name }}</td>
                </tr>
                <tr>
                  <td>Primary Page</td>
                  <td>{{ preview.name }}</td>
                </tr>
                <tr>
                  <td>Primary Page ID</td>
                  <td>{{ preview.id }}</td>
                </tr>
                <tr>
                  <td>Vertical</td>
                  <td>{{ preview.vertical }}</td>
                </tr>
                <tr>
                  <td>timezoneId</td>
                  <td>{{ getTimezone(preview.timezone_id) }}</td>
                </tr>
                <tr>
                  <td>Verification Status</td>
                  <td>{{ preview.verification_status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      type="submit"
      (click)="validateFacebookId()"
    >
      Show Meta business account info preview
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="close()">
      Close
    </button>
  </div>
</div>
