<div class="row align-items-center text-center mh-100">
  <div class="col-sm">
    <div class="d-flex justify-content-center">
      <div class="card">
        <div class="card-body text-start p-5" *ngIf="oldLogin">
          <h5 class="card-title">CS Tool Login</h5>
          <form [formGroup]="form" (ngSubmit)="onClickSubmit(form.value)">
            <div class="form-group mb-3">
              <label for="email">Email address</label>
              <input type="email" class="form-control" id="email" formControlName="email" />
              <div *ngIf="form.touched && form.get('email').errors?.['required']">
                <span class="text-danger">Email is required.</span>
              </div>
              <div *ngIf="form.touched && form.get('email').errors?.['email']">
                <span class="text-danger">Email not valid.</span>
              </div>
              <div *ngIf="form.get('email').hasError('invalidDomain')">
                <span class="text-danger">Email domain is not valid.</span>
              </div>
            </div>
            <div class="form-group mb-3">
              <label for="password">Password</label>
              <input type="password" class="form-control" id="password" formControlName="password" />
              <div *ngIf="form.touched && form.get('password').errors?.['required']">
                <span class="text-danger">Password is required.</span>
              </div>
            </div>
            <button type="button" class="btn btn-lg btn-primary ha" type="submit" [disabled]="!form.valid">
              Login
            </button>
          </form>
          <p class="card-text pt-3">
            The CS Tool requires 360dialog Hub Admin credentials. If you don't
            have these credentials please reach out to the L2 CS Team.
          </p>
          <p class="card-text pt-3">
            ©{{ currentYear }} All Rights Reserved. <br />
            <a class="text-dark" href="https://www.360dialog.com/en/contact/#dataprivacy">Privacy,</a>
            and
            <a class="text-dark" href="https://www.360dialog.com/en/contact">Imprint</a>
          </p>
        </div>
        <div class="card-body text-start p-5" *ngIf="!oldLogin">
          <h5 class="card-title">CS Tool Login</h5>
          <form>
            <button type="button" class="btn btn-lg btn-primary ha" type="submit" (click)="login()">
              Login
            </button>
          </form>
          <p class="card-text pt-3">
            ©{{ currentYear }} All Rights Reserved. <br />
            <a class="text-dark" href="https://www.360dialog.com/en/contact/#dataprivacy">Privacy,</a>
            and
            <a class="text-dark" href="https://www.360dialog.com/en/contact">Imprint</a>
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [checked]="oldLogin"
              (change)="onToggle($event)">
            <label class="form-check-label" for="flexSwitchCheckDefault">
              Old Login
            </label>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>