<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Migrate Phone to another WABA
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col">
        <ng-stepper #cdkStepper [linear]="true" [selectedIndex]="selectedIndex">
          <cdk-step [stepControl]="stepOne.form" [optional]="true" [completed]="stepOneCompleted">
            <ng-template cdkStepLabel>
              <div class="step-bullet">1</div>
              <div class="step-title">Prerequisites</div>
            </ng-template>
            <app-step-one #stepOne (nextStep)="changedStep($event)" [channel]="channel">
            </app-step-one>
          </cdk-step>
          <cdk-step [optional]="true" [completed]="stepTwoCompleted">
            <ng-template cdkStepLabel>
              <div class="step-bullet">2</div>
              <div class="step-title">Disable 2FA Pin</div>
            </ng-template>
            <app-step-two [channel]="channel" (nextStep)="changedStep($event)">
            </app-step-two>
          </cdk-step>
          <cdk-step [optional]="true" [completed]="stepThreeCompleted">
            <ng-template cdkStepLabel>
              <div class="step-bullet">3</div>
              <div class="step-title">Trigger & Verify Number</div>
            </ng-template>
            <app-step-three (nextStep)="changedStep($event)">
            </app-step-three>
          </cdk-step>
          <cdk-step [optional]="true" [completed]="stepFourCompleted">
            <ng-template cdkStepLabel>
              <div class="step-bullet">4</div>
              <div class="step-title">Complete Migration</div>
            </ng-template>
            <app-step-four [channel]="channel" (nextStep)="changedStep($event)">
            </app-step-four>
          </cdk-step>
        </ng-stepper>


      </div>
    </div>
  </div>
</div>
