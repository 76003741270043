import { Component, OnChanges, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges {
  @Input() dropdownItems: Array<string>;
  @Input() selectedItem: string;
  @Input() defaultDropdownOption: string;
  @Input() disabled = false;
  @Output() dropdownSelection = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    this.dropdownSelection.emit(this.selectedItem);
  }

  dropdownItemSelected($event: MouseEvent) {
    this.selectedItem = ($event.target as HTMLButtonElement).value;
    this.dropdownSelection.emit(this.selectedItem);
  }
}
