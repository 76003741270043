import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/authentication/authentication.service';
import { AuthenticationService as AuthService } from './core/services/auth.service';
import { HelperService } from './core/services/helper/helper.service';
import { Router } from '@angular/router';
import { UsersService } from './core/services/userService/users.service';
import { SharedDataService } from './core/services/shared-data/shared-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isSideNavCollapsed: boolean = false;
  screenWidth: number = 0;
  currentUrl: string = '';
  checkedRoleCount = 0;
  isDarkMode: boolean = false;

  constructor(
    private helperService: HelperService,
    public authService: AuthenticationService,
    public newAuthService: AuthService,
    private router: Router,
    private userService: UsersService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.helperService.showSpinner();
    setTimeout(() => {
      this.helperService.hideSpinner(); // Schedule the next execution
    }, 1000);
    // if(localStorage.getItem('loginType') == 'new') {
    //   this.newAuthService.isLoading$.subscribe({
    //     next: (loading) =>{
    //       if (loading) {
    //         this.helperService.showSpinner();
    //       } else {
    //         this.helperService.hideSpinner();
    //       }
    //     }
    //   })
    // }
    this.setRedirectUrl();
    this.checkRoles();
  }

  copyText() {
    this.helperService.copyText(window.location.href);
  }

  private checkRoles(): void {
    if (localStorage.getItem('loginType') === 'old') {
      this.authService.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn && !!localStorage.getItem('hub_email')) {
          if (this.checkedRoleCount == 0) {
            this.checkedRoleCount = 1;
            this.userService
              .checkUser(localStorage.getItem('hub_email'))
              .subscribe({
                next: (res) => {
                  // if(res.preferences) {
                  // this.isDarkMode = res.preferences['theme'] != 'light';
                  // }
                  this.sharedDataService.setUser(res);
                },
                error: (err) => {},
              });
          }
        } else {
          this.router.navigateByUrl('/login');
        }
      });
    }
  }

  private setRedirectUrl(): void {
    const originalUrl = window.location.href;
    const parsedUrl = new URL(originalUrl);
    const path = parsedUrl.pathname + parsedUrl.search;
    localStorage.setItem('redirect_url', path);
  }
}
