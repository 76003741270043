import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { JiraIssueModel } from '../domains/models/jira.model';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class StackService {
  stackRoute: string = `${environment.tppCsToolAPI.url}/v1/stack`;

  constructor(private httpClient: HttpClient) {}

  generateAuthToken(
    app_id: string,
    username: string,
    password: string
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.stackRoute}/token/app/${app_id}/username/${username}/password/${password}`,
      {}
    );
  }

  generateBackupFile(app_id: string, token: string, body: {}): Observable<any> {
    const headers = new HttpHeaders({
      'Stack-Token': token,
    });
    return this.httpClient.post<any>(
      `${this.stackRoute}/app/${app_id}/settings/backup`,
      body,
      {
        headers: headers,
      }
    );
  }
}
