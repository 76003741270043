import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-object-modal',
  templateUrl: './object-modal.component.html',
  styleUrls: ['./object-modal.component.scss']
})
export class ObjectModalComponent {

  object;
  title: string;

  constructor(protected activeModal: NgbActiveModal) {}

  close(){
    this.activeModal.dismiss();
  }

}
