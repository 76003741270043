export const environment = {
  name: 'stage',
  sentry: {
    tracePropagationTargets: 'https://staging-cstool.360dialog.io',
    dsn: 'https://dcdbfd7cba63bf5d0927be7ec6e07f44@o924116.ingest.sentry.io/4506671706537984',
  },
  production: false,
  stage: true,
  hubApi: 'https://hub-staging.360dialog.io',
  hubWabaApi: 'https://waba.hub-staging.360dialog.io',
  hubWabaCloudApi: 'https://waba-v2.360dialog.io',
  pltApi: 'https://cs-tool-v2.360dialog.io/api/plt',
  webhook: 'https://cs-tool-v2.360dialog.io/api/webhook',
  csToolApi: 'https://cs-tool-v2.360dialog.io/api',
  wabaStack: 'https://cs-tool-v2.360dialog.io/api/waba',
  user: 'https://cs-tool-v2.360dialog.io/api/user',
  authUser: 'https://cs-tool-v2.360dialog.io/api/auth/user',
  phoneNumberActivity:
    'https://cs-tool-v2.360dialog.io/api/phone-number-activity',
  csToolApiHelperController: 'https://cs-tool-v2.360dialog.io/api/functions',
  csToolApiInsightController: 'https://cs-tool-v2.360dialog.io/api/insight',
  csToolApiPltController: 'https://cs-tool-v2.360dialog.io/api/plt',
  csToolApiIncidentController: 'https://cs-tool-v2.360dialog.io/api/incident',
  csToolApiWaNotificationGroupController:
    'https://cs-tool-v2.360dialog.io/api/wa-notificaiton-group',
  csToolApiInstatusController: 'https://cs-tool-v2.360dialog.io/api/instatus',
  csToolApiEmailTemplatesController:
    'https://cs-tool-v2.360dialog.io/api/email-template',
  csToolApiPartnerLabelController:
    'https://cs-tool-v2.360dialog.io/api/partner-labels',
  csToolApiIntercomController: 'https://cs-tool-v2.360dialog.io/api/intercom',
  csToolApiJiraController: 'https://cs-tool-v2.360dialog.io/api/jira',
  csToolApiReviewController:
    'https://cs-tool-v2.360dialog.io/api/intercom-review',
  tppCsToolAPI: {
    url: 'https://tpp-cs-tool.hub-staging.360dialog.io',
    token: 'kmcX3IyFp8Z#2!WLNkevDn$k@txud*w5qPh8fy8B28Znr8w%bjuRDdtM9SND',
  },
  tosApi: {
    url: 'https://terms.hub-staging.360dialog.io',
    token: '015a7d4d-c6e4-406a-a8c8-bb9e40b5ad15',
  },
  auth0: {
    domain: '360dialog-cs-tool.eu.auth0.com',
    clientId: 'kKlua53T6Eu1LW7ZG7ogwrvLum8sGjWe',
    authorizationParams: {
      audience: 'https://tpp-cs-tool-api',
      redirectUrl: window.location.origin,
    },
  },
  intercomAppId: 'w5qvfu58',
};
