<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <span class="mb-2">Final step is to patch all of the information in the hub:</span>
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Patch Waba ID & Namespace
          <i class="bi bi-check text-success fs-3" *ngIf="stepOneCompleted"></i>
          <i class="bi bi-x text-danger fs-3" *ngIf="!stepOneCompleted"></i>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Patch Phone Number ID
          <i class="bi bi-check text-success fs-3" *ngIf="stepTwoCompleted"></i>
          <i class="bi bi-x text-danger fs-3" *ngIf="!stepTwoCompleted"></i>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Re-register number
          <i class="bi bi-check text-success fs-3" *ngIf="stepThreeCompleted"></i>
          <i class="bi bi-x text-danger fs-3" *ngIf="!stepThreeCompleted"></i>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Enable 2FA
          <i class="bi bi-check text-success fs-3" *ngIf="stepFourCompleted"></i>
          <i class="bi bi-x text-danger fs-3" *ngIf="!stepFourCompleted"></i>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Sync Templates
          <i class="bi bi-check text-success fs-3" *ngIf="stepFiveCompleted"></i>
          <i class="bi bi-x text-danger fs-3" *ngIf="!stepFiveCompleted"></i>
        </li>
      </ul>
    </div>
    <div class="col-12 mt-2" *ngIf="showProgress">
      <p><ngb-progressbar type="success" textType="white" [value]="progress" [showValue]="true"></ngb-progressbar></p>
    </div>
    <div class="col-12 mt-2" *ngIf="stepOneCompleted && !stepTwoCompleted">
      <div class="d-flex justify-content-center">
        <button type="button" class="btn-modal-proceed" type="submit" [ngStyle]="{ width: '300px' }"
          (click)="markAsComplete()">
          Mark re-regsiter as complete
        </button>
      </div>
    </div>
    <div class="col-12 mt-2" *ngIf="!completedMigration">
      <div class="d-flex justify-content-center">
        <button type="button" class="btn-modal-proceed" type="submit" [ngStyle]="{ width: '230px' }"
          (click)="handleFourthStep()">
          Complete Migration
        </button>
      </div>
    </div>
    <div class="col-12 mt-2 text-center" *ngIf="completedMigration">
      <span class="mb-2"><b>Migration completed succesfully! Please refresh the page so that the new data loads.</b></span>
    </div>
  </div>
  <div class="row">
    <div class="col text-center pt-1" *ngIf="errorMessage">
      <span class="text-danger">{{ errorMessage }}</span>
    </div>
  </div>
</div>
