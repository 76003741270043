<div>
  <div class="modal-header">
    <div class="d-inline-flex align-items-center">
      <h4 class="modal-title me-2">Email History</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" class="pb-2" *ngIf="action">
      <label>Email</label>
      <input type="text" class="form-control" formControlName="email">
    </form>
  </div>
  <div class="px-1">
    <ng-container *ngIf="search && messagesCount == 0">
      <ng-container *ngTemplateOutlet="noMessages"></ng-container>
    </ng-container>
    <ng-container *ngIf="search && messagesCount != 0">
      <ng-container *ngTemplateOutlet="messagesExist"></ng-container>
    </ng-container>
  </div>
  <div *ngIf="singleMessageSearch">
    <ng-container>
      <ng-container *ngTemplateOutlet="singleMessage"></ng-container>
    </ng-container>
  </div>
  <div *ngIf="suppressionExist">
    <ng-container>
      <ng-container *ngTemplateOutlet="suppressionExist"></ng-container>
    </ng-container>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <div>
      <button type="button" class="btn btn-light" (click)="close()">Close</button>
    </div>
    <div>
      <button class="btn btn-primary me-2" *ngIf="action" type="submit" (click)="checkEmail()"
        [disabled]="!this.form.valid">Check
        History
      </button>
      <button class="btn btn-primary" type="submit" (click)="checkSuppression()"
        [disabled]="action ? !this.form.valid : false">Check
        Suppression
      </button>
    </div>
  </div>
</div>

<ng-template #noMessages>
  <div class="d-flex justify-content-center p-1">
    There are no emails sent to {{ action ? form.controls.email.value : email}}.
  </div>
</ng-template>

<ng-template #messagesExist>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" *ngFor="let column of displayColumns"> {{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let message of messages.messages">
        <td>{{ message.from_email }}</td>
        <td>{{ message.to_email }}</td>
        <td>{{ message.subject }}</td>
        <td>{{ message.last_event_time }}</td>
        <td>
          <div [ngClass]="message.status === 'delivered' ? 'green-box' : 'red-box'">
            {{ message.status }}
          </div>
        </td>
        <td>
          <div>
            <button type="button" class="btn btn-outline-primary" ngbTooltip="View message events"
              (click)="getMessage(message.msg_id)"><i class="bi bi-info-square"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #singleMessage>
  <div *ngFor="let event of messageEvents" class="card mb-3">
    <div class="card-body">
      <h5 class="card-title text-uppercase">{{ event.event_name }}</h5>
      <ul class="list-group">
        <li *ngFor="let key of getKeys(event)" class="list-group-item">
          <strong>{{ key }}:</strong> {{ event[key] }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #suppressionExist>
  <div class="p-3">
    <div *ngIf="blocks.length !== 0">
      <span class="text-danger">Email exists in block list!</span>
      <button class="btn btn-primary ms-2" type="submit" *ngIf="sendgridWrite" (click)="deleteBlockByEmail()">
        Remove from block list
      </button>
    </div>
    <div *ngIf="bounces.length !== 0">
      <span class="text-danger">Email exists in bounce list!</span>
      <button class="btn btn-primary ms-2" type="submit" *ngIf="sendgridWrite" (click)="deleteBounceByEmail()">
        Remove from bounce list
      </button>
    </div>
    <div *ngIf="spams.length !== 0">
      <span class="text-danger">Email exists in spam list!</span>
      <button class="btn btn-primary ms-2" type="submit" *ngIf="sendgridWrite" (click)="deleteSpamByEmail()">
        Remove from spam list
      </button>
    </div>
    <div *ngIf="unsubscribes.length !== 0">
      <span class="text-danger">Email exists in unsubscribe list!</span>
      <button class="btn btn-primary ms-2" type="submit" *ngIf="sendgridWrite" (click)="deleteUnsubscribeByEmail()">
        Remove from unsubscribe list
      </button>
    </div>
  </div>
</ng-template>