import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  loadingObservables = {};
  constructor() {}

  setSubscription(id) {
    this.loadingObservables[id] = new BehaviorSubject(false);
  }

  show(component) {
    // Delay for 300 ms in case the main component calls before subscription is set by directive
    setTimeout(() => {
      this.loadingObservables[component.constructor.ɵcmp.selectors[0]]?.next(
        true
      );
    }, 100);
  }

  hide(component) {
    setTimeout(() => {
      this.loadingObservables[component.constructor.ɵcmp.selectors[0]]?.next(
        false
      );
    }, 100);
  }

  showBySelector(selector) {
    // Delay for 300 ms in case the main component calls before subscription is set by directive
    setTimeout(() => {
      this.loadingObservables[selector]?.next(true);
    }, 100);
  }

  hideBySelector(selector) {
    this.loadingObservables[selector]?.next(false);
  }
}
