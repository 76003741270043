import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CsToolApiResponseModel } from 'src/app/core/domains/models/cs-tool-api-response.model';
import { SubscribedAppModel } from 'src/app/core/domains/models/subscribed-app.model';
import { WabaUsersModel } from 'src/app/core/domains/models/waba-users.model';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-subscribe-app-modal',
  templateUrl: './subscribe-app-modal.component.html',
  styleUrls: ['./subscribe-app-modal.component.scss']
})
export class SubscribeAppModalComponent implements OnInit {
  form: FormGroup;
  check: boolean = false;

  displayColumnsUsers: Array<string> = [
    'ID',
    'Name',
    'Tasks'
  ];
  displayColumnsApp: Array<string> = [
    'ID',
    'Link',
    'Name'
  ];
  wabaUsers: WabaUsersModel[] = []
  subscribedApp: SubscribedAppModel[] = [];
  containsWabaManager: boolean = false
  isSubscribedToV2: boolean = false
  loadingData: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    protected activeModal: NgbActiveModal,
    protected helperService: HelperService,
    protected csToolApiService: CsToolApiService

    ) { }

  ngOnInit(): void {
    this.generateForm();
  }

  checkWaba() {
    this.loadingData = true;
    this.check = true;
    this.wabaUsers = []
    this.subscribedApp = [];
    this.containsWabaManager = false
    this.isSubscribedToV2 = false
    this.checkWabaManager();
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      externalId: new FormControl('', [Validators.required]),
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  checkWabaManager() {
    this.helperService.showSpinner();
    this.csToolApiService
    .getWabaUsers(this.form.controls['externalId'].value)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        if (res.data?.waba?.error) {
          this.helperService.openErrorModal('Failed to get WABA Users.', res.data?.waba?.error);
        } else {
          this.wabaUsers = res.data.waba.data;
          this.containsWabaManager = this.wabaUsers.some(item => item.id === "125288958872789");
          this.loadingData = false;
          if (this.containsWabaManager) {
            this.getSubscribedApps();
          } else {
            this.helperService.hideSpinner(false);
          }
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to get WABA Users.', err);
      },
    });
  }

  getSubscribedApps() {
    this.csToolApiService
    .getSubscribedApps(this.form.controls['externalId'].value)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        this.helperService.hideSpinner(false);
        if (res.data?.waba?.error) {
          this.helperService.openErrorModal('Failed to get Subscribed Apps.', res.data?.waba?.error);
        } else {
          this.subscribedApp = res.data.waba.data;
          this.isSubscribedToV2 = this.subscribedApp.some(item => item['whatsapp_business_api_data'].id === "782368959283666");
          this.helperService.hideSpinner(false);
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to get Subscribed Apps.', err);
      },
    });
  }

  assignWabaManager() {
    this.helperService.showSpinner();
    this.csToolApiService
    .assignWabaManager(this.form.controls['externalId'].value)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        if (res.data?.waba?.error) {
          this.helperService.openErrorModal('Failed to get Assign Waba Manager.', res.data?.waba?.error);
        } else {
          this.wabaUsers.push({
            id: '125288958872789',
            name: "waba_manager",
            tasks: ["MANAGE"]
          });
          this.containsWabaManager = true;
          this.getSubscribedApps();
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to get Assign Waba Manager.', err);
      },
    });
  }

  addSubscribeApp() {
    this.helperService.showSpinner();
    this.csToolApiService
    .addSubscribeApp(this.form.controls['externalId'].value)
    .subscribe({
      next: (res: CsToolApiResponseModel<any>) => {
        if (res.data?.waba?.error) {
          this.helperService.openErrorModal('Failed to set Subscribed APP.', res.data?.waba?.error);
        } else {
          this.isSubscribedToV2 = true;
          this.helperService.hideSpinner();
        }
      },
      error: (err) => {
        this.helperService.openErrorModal('Failed to set Subscribed APP.', err);
      },
    });
  }

}
