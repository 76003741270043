import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  header: string;

  title: string;

  description: string;

  confirmBtnText: string;

  constructor(protected activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  confirm() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.dismiss();
  }
}
