<div class="input-group search pt-2">
  <div class="search-input-container">
    <img
      (click)="onSerach($event.target['value'])"
      class="search-icon"
      src="assets/images/icons/search.svg"
      alt=""
    />
    <input
      type="text"
      class="search-input"
      [placeholder]="placeholder"
      [value]="value"
      (keyup)="onSerach($event.target['value'])"
    />
  </div>
</div>
