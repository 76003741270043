import { Component, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from './../../core/services/spinner/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() id: string;
  subscription: Subscription;
  loading = false;

  @HostBinding('style.display') display = 'none';

  constructor(public spinnerService: SpinnerService) {}

  ngOnInit() {
    if (!!this.id) {
      if (!this.spinnerService.loadingObservables[this.id]) {
        this.spinnerService.setSubscription(this.id);
      }

      this.subscription = this.spinnerService.loadingObservables[
        this.id
      ].subscribe((loading) => {
        if (loading) {
          this.display = 'flex';
        } else {
          this.display = 'none';
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.spinnerService.hideBySelector(this.id);
  }
}
