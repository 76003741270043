<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Deleted Channel Information</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12 p-2">
        <div class="form-group">
          <label>Client ID</label>
          <input type="text" class="form-control" [(ngModel)]="clientId">
        </div>
        <div class="form-group">
          <label>Partner ID</label>
          <input type="text" class="form-control" [(ngModel)]="partnerId">
        </div>
      </div>
      <ng-container *ngIf="channels.length > 0">
        <div class="row">
          <div class="col-12">
            <div class="input-group mx-1">
              <div class="input-group-prepend">
                <h5>Details</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" *ngFor="let column of displayColumns">
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let channel of channels">
                  <td>{{ channel?.id ?? "N/A"}} </td>
                  <td>{{ channel?.setup_info.phone_number ?? "N/A"}} </td>
                  <td>{{ channel?.setup_info.phone_name ?? "N/A"}} </td>
                  <td>{{ channel?.['waba_account_id'] ?? "N/A"}} </td>
                  <td>{{ channel?.cancelled_at ? (channel?.cancelled_at | date : 'dd.MM.YYYY HH:mm') : "N/A"}} </td>
                  <td>{{ channel?.terminated_at ? (channel?.terminated_at | date : 'dd.MM.YYYY HH:mm') : "N/A"}} </td>
                  <td>{{ channel?.['modified_by']?.['user_name'] ?? "N/A"}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="channels.length == 0 && search">
        <div class="row">
          <div class="col-12">
            <div class="input-group mx-1">
              <div class="input-group-prepend">
                <h5>Details</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" *ngFor="let column of displayColumns">
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="7" class="text-center">There are no channels related to {{ clientId }} & {{ partnerId }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" type="submit" (click)="getDeletedChannel()">
      Get Details
    </button>
    <button type="button" class="btn btn-warning" type="submit" *ngIf="channels.length > 0" (click)="refundBalance()">
      Refund
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="close()">
      Close
    </button>
  </div>
</div>