import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Channel } from 'src/app/core/domains/models/channels.module';
import { EndpointDetailsModel } from 'src/app/core/domains/models/endpoint-details.model';
import { OboToSharedModel } from 'src/app/core/domains/models/obo-to-shared.model';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { OboToSharedService } from 'src/app/core/services/obo-to-shared/obo-to-shared.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']

})
export class StepFourComponent implements OnInit, OnDestroy {

  @Input() channel: Channel;
  @Output() nextStep = new EventEmitter<any>();

  private oboToSharedValSubscription: Subscription;
  oboToEsVal: OboToSharedModel;

  progress: number = 0;
  showProgress: boolean = false;

  results: any[] = [];

  errorMessage: string = '';

  stepOneCompleted: boolean = false;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  stepFourCompleted: boolean = false;
  stepFiveCompleted: boolean = false;
  completedMigration: boolean = false;

  constructor(
    private oboToSharedService: OboToSharedService,
    private helperService: HelperService,
    private csToolApiService: CsToolApiService
    ) { }

  ngOnInit(): void {
    this.subscribeToSharedData();
  }

  ngOnDestroy() {
    this.oboToSharedValSubscription.unsubscribe();
  }

  handleFourthStep(): void {
    this.progress = 0;
    this.errorMessage = '';
    this.showProgress = true;
    const endpoints: EndpointDetailsModel[] = []
    if(!this.stepOneCompleted) {
      endpoints.push(
        {
          method: 'POST',
          url: `${environment.csToolApi}/migration/handleFourthStep/${this.oboToEsVal.id}`,
          body: {
            step: '1',
            partnerId: this.channel?.partner?.id,
            clientId: this.channel?.client?.id,
            wabaId: this.channel?.waba_account?.id
          }
        }
      )
    } else{
      this.progress = 20;
    }
    if (!this.stepTwoCompleted) {
      endpoints.push(
        {
          method: 'POST',
          url: `${environment.csToolApi}/migration/handleFourthStep/${this.oboToEsVal.id}`,
          body: {
            step: '2',
            partnerId: this.channel?.partner?.id,
            channelId: this.channel?.id
          }
        }
      )
    } else {
      this.progress = 40;
    }
    if (!this.stepThreeCompleted) {
      endpoints.push(
        {
          method: 'POST',
          url: `${environment.csToolApi}/migration/handleFourthStep/${this.oboToEsVal.id}`,
          body: {
            step: '3',
            userPassword: this.channel?.integration?.auth?.password,
            appId: this.channel?.integration?.app_id
          }
        }
      )
    } else {
      this.progress = 60;
    }

    if (!this.stepFourCompleted) {
      endpoints.push(
        {
          method: 'POST',
          url: `${environment.csToolApi}/migration/handleFourthStep/${this.oboToEsVal.id}`,
          body: {
            step: '4',
            appId: this.channel?.integration?.app_id,
            userPassword: this.channel?.integration?.auth?.password
          }
        }
      )
    } else {
      this.progress = 80;
    }
    if (!this.stepFiveCompleted) {
      endpoints.push(
        {
          method: 'POST',
          url: `${environment.csToolApi}/migration/handleFourthStep/${this.oboToEsVal.id}`,
          body: {
            step: '5',
            partnerId: this.channel?.partner?.id,
            wabaId: this.channel?.waba_account?.id
          }
        }
      )
    } else {
      this.progress = 100;
    }
    this.csToolApiService.handleFourthStep(endpoints).subscribe({
      next: (res) => {
        if(res.success) {
          this.oboToSharedService.setPayload(res.migration);
          if (res.migration.completed == 1) {
            this.completedMigration = true;
            this.nextStep.emit("completed");
          }
        } else {
          this.errorMessage = JSON.stringify(res['migration']['error_message']);
        }
      },
      error: (err) => {
        this.helperService.showErrorToastr("An error occurred. Please post the details to #cs-tool.")
      }
    })
  }

  markAsComplete(): void {
    this.errorMessage = '';
    this.helperService.showSpinner();
    this.csToolApiService.markAsComplete(this.oboToEsVal.id).subscribe({
      next: (res) => {
        if(res.success) {
          if(!this.helperService.isObjectEmpty(res.migration)) {

            this.oboToSharedService.setPayload(res.migration);
          } else {
            this.errorMessage = 'Migration failed. Please post the issue to #cs-tool channel.';
          }
        } else {
          this.errorMessage = res.error;
        }
        this.helperService.hideSpinner(false);
      },
      error: (err) => {
       this.helperService.hideSpinner(false);
       this.helperService.showErrorToastr('Failed to mark step as complete. Please post the issue to cs-tool channel.')
      }
    });
  }

  private subscribeToSharedData() {
    this.oboToSharedValSubscription = this.oboToSharedService.oboToSharedVal$.subscribe(
      (payload: any) => {
        this.oboToEsVal = payload;
        if (!this.helperService.isObjectEmpty(this.oboToEsVal)) {
          const stepFour = typeof this.oboToEsVal.step_four === 'string'
            ? JSON.parse(this.oboToEsVal.step_four)
            : this.oboToEsVal.step_four;

          this.stepOneCompleted = stepFour?.['step_one'] === 1;
          if (this.stepOneCompleted) {
            this.progress = 20;
          }

          this.stepTwoCompleted = stepFour?.['step_two'] === 1;
          if (this.stepTwoCompleted) {
            this.progress = 40;
          }

          this.stepThreeCompleted = stepFour?.['step_three'] === 1;
          if (this.stepThreeCompleted) {
            this.progress = 60;
          }

          this.stepFourCompleted = stepFour?.['step_four'] === 1;
          if (this.stepFourCompleted) {
            this.progress = 80;
          }

          this.stepFiveCompleted = stepFour?.['step_five'] === 1;
          if (this.stepFiveCompleted) {
            this.progress = 100;
          }
        }
      }
    );
  }
}
