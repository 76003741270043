import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { SpinnerComponent } from './../spinner/spinner.component';

@Directive({
  selector: '[appSpinner]',
})
export class SpinnerDirective {
  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    public spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent);

    const componentRef =
      this.viewContainerRef.createComponent(componentFactory);

    componentRef.instance.id = this.element.nativeElement.localName;
    this.spinnerService.setSubscription(this.element.nativeElement.localName);

    const host = this.element.nativeElement;
    host.append(componentRef.location.nativeElement);
    host.style.position = 'relative';
    host.style.display = 'block';
    // setTimeout(() => {
    //   host.removeChild(componentRef.location.nativeElement);
    // }, 3000);
  }
}
