import { Injectable } from '@angular/core';
import { Channel } from '../../domains/models/channels.module';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private container: Map<string, any> = new Map();

  constructor() {}

  getChannelFromCache(phoneNumber: string): Promise<any> {
    return new Promise((resolve) => {
      this.container.forEach((element, key) => {
        if (
          key.indexOf('/v1/channels') != -1 &&
          !!element.body?.partner_channels
        ) {
          element.body?.partner_channels?.forEach((channel) => {
            if (
              !!channel.setup_info &&
              channel.setup_info.phone_number == phoneNumber
            ) {
              return resolve(channel);
            }
          });
        }
      });
      return resolve(null);
    });
  }

  setNewChannel(newChannel: Channel) {
    this.container.forEach((element, key) => {
      if (
        key.indexOf('/v1/channels') != -1 &&
        !!element.body?.partner_channels
      ) {
        element.body?.partner_channels?.forEach((channel, index) => {
          if (
            !!channel.setup_info &&
            channel.setup_info.phone_number ==
              newChannel.setup_info.phone_number
          ) {
            element.body.partner_channels[index] = newChannel;
            this.container.set(key, element);
          }
        });
      }
    });
  }

  set(key: string, value: any) {
    this.container.set(key, value);
  }

  get(key: string) {
    return this.container.get(key);
  }

  delete(key: string) {
    this.container.delete(key);
  }
}
