<div class="modal-header">
  <div class="d-inline-flex align-items-center">
    <h4 class="modal-title me-2">{{ title }}</h4>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <label>{{ inputFieldOne }}</label>
      <input type="text" class="form-control" formControlName="editValueOne">
    </div>
    <div class="form-group">
      <label>{{ inputFieldTwo }}</label>
      <input type="text" class="form-control" formControlName="editValueTwo">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn-modal-proceed" type="submit" [disabled]="!this.form.valid" (click)="confirm()">
    {{ confirmationText }}
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">
    Close
  </button>
</div>
