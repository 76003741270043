<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col">
        <div *ngIf="url">
          <img [src]="url" height="150" width="150" />
        </div>
        <input
          type="file"
          id="img"
          (change)="onSelect($event)"
          accept="image/*"
        />
        <p
          class="text-muted pt-2"
          *ngIf="description"
          [innerHtml]="description"
        ></p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    type="submit"
    (click)="confirm()"
  >
    Save
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="close()">
    Close
  </button>
</div>
