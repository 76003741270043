import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Channel } from 'src/app/core/domains/models/channels.module';
import { OboToSharedModel } from 'src/app/core/domains/models/obo-to-shared.model';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { OboToSharedService } from 'src/app/core/services/obo-to-shared/obo-to-shared.service';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit, OnDestroy {

  @Input() channel: Channel;
  @Output() nextStep = new EventEmitter<any>();

  private oboToSharedValSubscription: Subscription;
  oboToEsVal: OboToSharedModel;

  errorMessage: string = '';

  constructor(
    private oboToSharedService: OboToSharedService,
    private helperService: HelperService,
    private csToolApiService: CsToolApiService
    ) { }

  ngOnInit(): void {
    this.subscribeToSharedData();
  }

  ngOnDestroy() {
    this.oboToSharedValSubscription.unsubscribe();
  }

  disalbeMfaPin(): void {
    this.errorMessage = '';
    this.helperService.showSpinner();
    this.csToolApiService.handleSecondStep(
      this.oboToEsVal.id,
      this.channel?.integration?.auth?.password,
      this.channel?.integration?.app_id
      ).subscribe({
      next: (res) => {
        if(res.success) {
          this.helperService.showSuccessToastr('2FA disabled succesfully.');
          this.oboToSharedService.setPayload(res.migration);
        } else {
          this.errorMessage = this.oboToSharedService.parseErrorMessage(res);
        }
        this.helperService.hideSpinner(false);
      },
      error: (err) => {
       this.helperService.hideSpinner(false);
       this.helperService.showErrorToastr('Failed to disable 2FA. Please post the issue to cs-tool channel.')
      }
    });
  }

  navigateToNextStep(): void {
    if (this.oboToEsVal.host_type == 'meta_cloud_api') {
      this.helperService.showSpinner();
      this.csToolApiService.handleSecondStepCloud(this.oboToEsVal.id).subscribe({
        next: (res) => {
          if(res.success) {
            this.oboToSharedService.setPayload(res.migration);
            this.nextStep.emit('stepTwo');
          } else {
            this.errorMessage = this.oboToSharedService.parseErrorMessage(res);
          }
          this.helperService.hideSpinner(false);
        },
      });
    } else {
      this.nextStep.emit('stepTwo');
    }
  }

  private subscribeToSharedData() {
    this.oboToSharedValSubscription = this.oboToSharedService.oboToSharedVal$.subscribe(
      (payload: any) => {
        this.oboToEsVal = payload;
      }
    );
  }

}
