<div class="modal-header">
  <div class="d-inline-flex align-items-center">
    <h4 class="modal-title me-2">Subscribe App</h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <label>Waba External ID</label>
    <div class="input-group">
      <input type="text" class="form-control" formControlName="externalId">
      <div class="input-group-prepend">
        <button class="btn btn-primary" type="submit" (click)="checkWaba()">Check</button>
      </div>
    </div>
  </form>
  <div *ngIf="check && !loadingData" class="mt-2">
    <h6 class="fb">WABA Users</h6>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" *ngFor="let column of displayColumnsUsers">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of wabaUsers">
          <td class="align-middle">{{ user.id }}</td>
          <td class="align-middle">{{ user.name }}</td>
          <td class="align-middle">{{ user.tasks }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2 text-center" *ngIf="!containsWabaManager">
      <span class="text-danger">The WABA does not contain user waba_manager. <br></span>
      <button type="button" class="btn btn-primary" (click)="assignWabaManager()">Add waba_manager</button>
    </div>
    <div *ngIf="containsWabaManager">
      <h6 class="fb">Subscribed Apps</h6>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" *ngFor="let column of displayColumnsApp">
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let app of subscribedApp">
            <td class="align-middle">{{ app['whatsapp_business_api_data'].id }}</td>
            <td class="align-middle">{{ app['whatsapp_business_api_data'].link }}</td>
            <td class="align-middle">{{ app['whatsapp_business_api_data'].name }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2 text-center" *ngIf="isSubscribedToV2">
        <span class="text-success">The WABA is already subscribed.<br></span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn-modal-proceed"
    type="submit"
    (click)="addSubscribeApp()"
    *ngIf="!isSubscribedToV2 && containsWabaManager"
  >
    Subscribe
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">
    Close
  </button>
</div>
