<div class="container-fluid">
  <hr>
  <div class="row">
    <div class="col">
      <form [formGroup]="form" class="pb-2">
        <label>New WABA ID</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="newWabaId">
          <div class="input-group-prepend">
            <button class="btn btn-primary" type="submit" [disabled]="!isNewWabaValid()" (click)="checkWaba()">Check</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ngb-accordion #acc="ngbAccordion" activeIds="panel-one">
        <ngb-panel id="panel-one">
          <ng-template ngbPanelTitle>
            <span><b>Current WABA Information</b></span>
          </ng-template>
          <ng-template ngbPanelContent class="p-0">
            <ul class="list-group p-0" *ngIf="!isObjectEmpty(oldWaba); else emptyWaba">
              <li class="list-group-item rounded-0"><b>ID:</b> {{ oldWaba.id ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Name:</b> {{ oldWaba.name ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Currency:</b> {{ oldWaba.currency ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Primary Funding ID:</b> {{ oldWaba.primary_funding_id ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Timezone ID:</b> {{ oldWaba.timezone_id ?? 'N/A'}}</li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
  <div class="row pt-1" *ngIf="!isObjectEmpty(newWaba)">
    <div class="col">
      <ngb-accordion #acc="ngbAccordion" activeIds="panel-two">
        <ngb-panel id="panel-two">
          <ng-template ngbPanelTitle>
            <span><b>New WABA Information</b></span>
          </ng-template>
          <ng-template ngbPanelContent class="p-0">
            <ul class="list-group p-0">
              <li class="list-group-item rounded-0"><b>ID:</b> {{ newWaba.id ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Name:</b> {{ newWaba.name ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Currency:</b> {{ newWaba.currency ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Primary Funding ID:</b> {{ oldWaba.primary_funding_id ?? 'N/A'}}</li>
              <li class="list-group-item rounded-0"><b>Timezone ID:</b> {{ newWaba.timezone_id ?? 'N/A'}}</li>
            </ul>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
  <div class="row" *ngIf="!isObjectEmpty(newWaba)">
    <div class="col" *ngIf="!paymentSet">
      <form [formGroup]="form" class="pb-2">
        <label>Currency</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="cc">
          <div class="input-group-prepend">
            <button class="btn btn-primary"
            type="submit"
            (click)="setPaymentMethod()"
            [disabled]="!this.setPaymentMethodForm.valid">
            Set Payment Method
          </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col" *ngIf="paymentSet">
      <form [formGroup]="form" class="pb-2">
        <label>Country Code</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="cc">
          <div class="input-group-prepend">
            <button class="btn btn-primary"
            type="submit"
            (click)="startMigration()"
            [disabled]="!this.form.valid || isObjectEmpty(newWaba) || !paymentSet">
            Start migration
          </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row" *ngIf="channel.waba_account.fb_account_status != 'verified'">
    <div class="col">
      <li class="list-group-item">
        <span class="form-check-label">
          Business verification is <b>not completed.</b>
        </span>
        <i class="bi bi-x text-danger fs-4 align-middle"></i>
      </li>
    </div>
  </div>
  <div class="row" *ngIf="errorMessage">
    <div class="col text-center">
      <span class="text-danger">{{ errorMessage | json }}</span>
    </div>
  </div>
</div>
<ng-template #emptyWaba>
  <ul class="list-group p-0">
    <li class="list-group-item text-center rounded-0">Loading WABA information</li>
  </ul>
</ng-template>

