import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concatMap, from, takeWhile } from 'rxjs';
import { CsToolApiResponseModel } from 'src/app/core/domains/models/cs-tool-api-response.model';
import { environment } from 'src/environments/environment';
import { WabaPhoneDetails } from '../../domains/models/waba-phone-details.module';
import { BusinessCompliance } from '../../domains/models/business-compliance.module';
import { OboToSharedModel } from '../../domains/models/obo-to-shared.model';
import { EndpointDetailsModel } from '../../domains/models/endpoint-details.model';
import { Activities } from '../../domains/models/activities.module';
import { Ads } from '../../domains/models/ads.module';
import { Campaigns } from '../../domains/models/campaigns.module';
import { Incident, Incidents } from '../../domains/models/incident.model';
import { WaNotificationGroup } from '../../domains/models/wa-notification-group.model';
import { KnownIssueNotifications } from '../../domains/models/known-issue-notifications.module';
import { InstatusComponents } from '../../domains/models/instatus-components.model';
import { InstatusIncidentDetails } from '../../domains/models/instatus-incident-details.model';
import { EmailTemplates } from '../../domains/models/email-templates';
import { PartnerLabelsModel } from '../../domains/models/partner-label.model';
import { IntercomReviews } from '../../domains/models/intercom-review.model';

export interface StartMigrationBody {
  oldWabaId: string;
  newWabaId: string;
  phoneNnumber: string;
  cc: string;
}

@Injectable({
  providedIn: 'root',
})
export class CsToolApiService {
  constructor(private httpClient: HttpClient) {}

  getAllMigrations(): Observable<OboToSharedModel[]> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<OboToSharedModel[]>(
      `${environment.csToolApi}/migration/getAllMigrations`,
      { params }
    );
  }

  startMigration(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/start`,
      body
    );
  }

  markAsComplete(migrationId: string): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/markAsComplete/${migrationId}`,
      {}
    );
  }

  handleSecondStep(
    migrationId: string,
    userPassword: string,
    appId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/handleSecondStep/${migrationId}/${userPassword}/${appId}`,
      {}
    );
  }

  handleSecondStepCloud(migrationId: string): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/handleSecondStepCloud/${migrationId}`,
      {}
    );
  }

  handleThirdStep(migrationId: string, code: string): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/handleThirdStep/${migrationId}/${code}`,
      {}
    );
  }

  handleFourthStep(endpoints: EndpointDetailsModel[]): Observable<any> {
    return from(endpoints).pipe(
      concatMap((endpoint) => this.callEndpoint(endpoint)),
      takeWhile(
        (response) => response === null || response.success !== false,
        true
      )
    );
  }

  private callEndpoint(endpoint: EndpointDetailsModel): Observable<any> {
    let options = {};

    if (endpoint.method === 'GET') {
      options = {
        params: new HttpParams({ fromObject: endpoint.params }),
      };
    } else if (endpoint.method === 'POST') {
      options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: endpoint.body,
      };
    }

    return this.httpClient.request(endpoint.method, endpoint.url, options);
  }

  getMigration(oldWabaId, phoneNumber): Observable<OboToSharedModel> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<OboToSharedModel>(
      `${environment.csToolApi}/migration/${oldWabaId}/${phoneNumber}`,
      { params }
    );
  }

  setPaymentMethod(externalId, body) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApi}/graph/waba/${externalId}/setinfo`,
      body
    );
  }

  getSubscribedApps(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/getSubscribedApp`,
      { params }
    );
  }

  getWabaInfo(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/info`,
      { params }
    );
  }

  getWabaPhoneNumbers(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/phone_numbers`,
      { params }
    );
  }

  getWabaUsers(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/getWabaUsers`,
      { params }
    );
  }

  assignWabaManager(externalId): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/assignWabaManager`,
      {}
    );
  }

  assignWabaDev(externalId): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/assignWabaDev`,
      {}
    );
  }

  addSubscribeApp(externalId): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/addSubscribeApp`,
      {}
    );
  }

  // CAPI

  reRegisterCloudApiNumber(phoneNumberId, body): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${phoneNumberId}/reRegisterCloudApiNumber`,
      body
    );
  }

  getCloudApiBusinessCompliance(
    partnerId: string,
    appId: string,
    reset: boolean
  ): Observable<BusinessCompliance> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    var targetUrl = `${environment.csToolApi}/cloudApi/getCloudApiBusinessCompliance/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/business_compliance_info`;
    }
    return this.httpClient.get<BusinessCompliance>(targetUrl, { params });
  }

  updateCloudApiBusinessCompliance(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/cloudApi/updateCloudApiBusinessCompliance/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/business_compliance_info`;
    }
    return this.httpClient.post(targetUrl, body);
  }

  registerCloudApiDisplayName(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/cloudApi/registerCloudApiDisplayName`,
      body
    );
  }

  updateCloudApiWebhook(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/cloudApi/updateCloudApiWebhook/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      if (body['type'] == 'channel') {
        targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/v1/configs/webhook`;
      } else {
        targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/waba_webhook`;
      }
      delete body['type'];
    }
    return this.httpClient.post(targetUrl, body);
  }

  sendCloudApiMessage(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/cloudApi/sendTestMessage/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/messages`;
    }
    return this.httpClient.post(targetUrl, body);
  }

  triggerCloudOtp(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/cloudApi/triggerOtp`,
      body
    );
  }

  verifyCloudOtp(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/cloudApi/verifyOtp`,
      body
    );
  }

  getCapiPhoneInfo(externalId: string): Observable<WabaPhoneDetails> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<WabaPhoneDetails>(
      `${environment.csToolApi}/cloudApi/getCapiPhoneInfo/${externalId}`,
      { params }
    );
  }

  getCloudApiHealthStatus(
    partnerId: string,
    appId: string,
    reset: boolean
  ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    var targetUrl = `${environment.csToolApi}/cloudApi/getCloudApiHealthStatus/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/health_status`;
    }
    return this.httpClient.get<any>(targetUrl, { params });
  }

  getMultiWebhookInformation(
    partnerId: string,
    appId: string
  ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    var targetUrl = `${environment.csToolApi}/cloudApi/getMultiWebhookInformation/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/cloud/${partnerId}/${appId}/multi_webhook`;
    }
    return this.httpClient.get<any>(targetUrl, { params });
  }

  registerStack(
    userPassword: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/register-stack`,
      body
    );
  }

  registerStackWipe(
    userPassword: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/register-stack-wipe`,
      body
    );
  }

  verifyStack(userPassword: string, appId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/verify-stack`,
      body
    );
  }

  // custom request
  executeGetRequest(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/customRequest/get`,
      body,
      { observe: 'response' }
    );
  }

  executePostRequest(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/customRequest/post`,
      body,
      { observe: 'response' }
    );
  }

  executePatchRequest(body: {}): Observable<any> {
    return this.httpClient.patch(
      `${environment.csToolApi}/customRequest/patch`,
      body,
      { observe: 'response' }
    );
  }

  // graph
  getExternalInfo(externalId: string): Observable<WabaPhoneDetails> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<WabaPhoneDetails>(
      `${environment.csToolApi}/graph/waba/${externalId}`,
      { params }
    );
  }

  // HUB

  sendOnPremiseMessage(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/hubApi/sendOnPremiseMessage/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/on_premise/${partnerId}/${appId}/v1/messages`;
    }
    return this.httpClient.post(targetUrl, body);
  }

  getOnPremiseWebhook(
    partnerId: string,
    appId: string,
    reset: boolean = false
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/hubApi/getOnPremiseWebhook/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/on_premise/${partnerId}/${appId}/v1/configs/webhook`;
    }
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(targetUrl, { params });
  }

  updateOnPremiseWebhook(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/hubApi/updateOnPremiseWebhook/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/on_premise/${partnerId}/${appId}/v1/configs/webhook`;
    }
    return this.httpClient.post(targetUrl, body);
  }

  getOnPremiseBusinessCompliance(
    partnerId: string,
    appId: string,
    reset: boolean
  ): Observable<BusinessCompliance> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    var targetUrl = `${environment.csToolApi}/hubApi/getOnPremiseBusinessCompliance/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/on_premise/${partnerId}/${appId}/v1/settings/business/compliance_info`;
    }
    return this.httpClient.get<BusinessCompliance>(targetUrl, { params });
  }

  updateOnPremiseBusinessCompliance(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = `${environment.csToolApi}/hubApi/updateOnPremiseBusinessCompliance/${partnerId}/${appId}`;
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/on_premise/${partnerId}/${appId}/v1/settings/business/compliance_info`;
    }
    return this.httpClient.post(targetUrl, body);
  }

  // Graph API

  migratePhoneNumber(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/migratePhoneNumber`,
      body
    );
  }

  triggerOnPremOtp(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/triggerOnPremOtp`,
      body
    );
  }

  verifyOnPremOtp(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/verifyOnPremOtp`,
      body
    );
  }

  migrateTemplates(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/migrateTemplates`,
      body
    );
  }

  getCapiThroughput(phoneNumberId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${phoneNumberId}/getCapiThroughput`,
      { params }
    );
  }

  // ACTIVITY
  storePhoneNumberActivity(phoneNumber: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.phoneNumberActivity}/${phoneNumber}`,
      body
    );
  }

  getAllPhoneActivities(
    phoneNumber: number,
    offset: string,
    size: string,
    sort: string
  ): Observable<Activities[]> {
    const fromObject: { [index: string]: string } = {
      sort: sort,
      limit: size,
      offset: offset,
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Activities[]>(
      `${environment.phoneNumberActivity}/${phoneNumber}`,
      { params }
    );
  }

  // HELPER CONTROLLER
  restartSubscription(index: number, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiHelperController}/restartSubscription/${index}`,
      body
    );
  }

  testWebhookCallbackTypes(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiHelperController}/testWebhookCallbackTypes`,
      body
    );
  }

  checkSslCertificate(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiHelperController}/check-ssl-certificate`,
      body
    );
  }

  getAllocationId(businessId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get(
      `${environment.csToolApiHelperController}/get-allocation-id/${businessId}`,
      { params }
    );
  }

  verifyCreditLineDeteled(allocationId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get(
      `${environment.csToolApiHelperController}/verify-credit-line-deleted/${allocationId}`,
      { params }
    );
  }

  deleteCreditLine(allocationId: string): Observable<any> {
    return this.httpClient.delete(
      `${environment.csToolApiHelperController}/delete-credit-line/${allocationId}`
    );
  }

  // WABA CONTROLLER
  generateWabStackToken(userPassword: string, appId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/generateWabStackToken`,
      { params }
    );
  }

  updateAutoGC(userPassword: string, appId: string, body: {}) {
    return this.httpClient.patch<CsToolApiResponseModel<any>>(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/update-garbage-collector`,
      body
    );
  }

  executeGCMedia(userPassword: string, appId: string): Observable<any> {
    return this.httpClient.put(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/execute-garbage-collector-media`,
      {}
    );
  }

  executeGCMessage(userPassword: string, appId: string): Observable<any> {
    return this.httpClient.put(
      `${environment.csToolApi}/waba/${userPassword}/${appId}/execute-garbage-collector-message`,
      {}
    );
  }

  // INSIGHTS CONTROLLER
  getFacebookAccounts(clientId: string, appId: string): Observable<any> {
    var targetUrl = '';
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/insight`;
    } else {
      targetUrl = `${environment.csToolApiInsightController}`;
    }
    return this.httpClient.get<any>(
      `${targetUrl}/v1/clients/${clientId}/apps/${appId}/facebook-accounts`
    );
  }

  getAds(
    clientId: string,
    appId: string,
    adAccountId: string,
    offset: string,
    size: string
  ): Observable<Ads> {
    var targetUrl = '';
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/insight`;
    } else {
      targetUrl = `${environment.csToolApiInsightController}`;
    }
    const fromObject: { [index: string]: string } = {
      offset: offset,
      limit: size.toString(),
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Ads>(
      `${targetUrl}/v1/clients/${clientId}/apps/${appId}/ads/account/${adAccountId}`,
      { params }
    );
  }

  getCampaigns(
    clientId: string,
    appId: string,
    adAccountId: string,
    offset: string,
    size: string
  ): Observable<Campaigns> {
    var targetUrl = '';
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/insight`;
    } else {
      targetUrl = `${environment.csToolApiInsightController}`;
    }
    const fromObject: { [index: string]: string } = {
      offset: offset,
      limit: size.toString(),
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Campaigns>(
      `${targetUrl}/v1/clients/${clientId}/apps/${appId}/campaigns/account/${adAccountId}`,
      { params }
    );
  }

  enableClientForInsight(body: {}): Observable<any> {
    var targetUrl = '';
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/insight/v1/insights-allowed/`;
    } else {
      targetUrl = `${environment.csToolApiInsightController}/v1/insights-allowed`;
    }
    return this.httpClient.post<any>(targetUrl, body);
  }

  disableClientForInsight(clientId: string): Observable<any> {
    var targetUrl = '';
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/insight`;
    } else {
      targetUrl = `${environment.csToolApiInsightController}`;
    }
    return this.httpClient.delete<any>(
      `${targetUrl}/v1/insights-allowed/${clientId}`
    );
  }

  getAllowedClient(clientId: string): Observable<any> {
    var targetUrl = '';
    if (localStorage.getItem('loginType') == 'new') {
      targetUrl = `${environment.tppCsToolAPI.url}/v1/insight`;
    } else {
      targetUrl = `${environment.csToolApiInsightController}`;
    }
    return this.httpClient.get<any>(
      `${targetUrl}/v1/insights-allowed/${clientId}`
    );
  }

  // PLTAPI CONTROLLER
  triggerRegistration(appId: string, body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiPltController}/api/v1/stack_actions/account/${appId}`,
      body
    );
  }

  // INCIDENT CONTROLLER
  createIncident(body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}`,
      body
    );
  }

  getIncidents(fromObject: {}): Observable<Incidents> {
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Incidents>(
      `${environment.csToolApiIncidentController}`,
      { params }
    );
  }

  getIncident(incidentId: string): Observable<Incident> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Incident>(
      `${environment.csToolApiIncidentController}/${incidentId}`,
      { params }
    );
  }

  updateIncident(incidentId: string, body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}`,
      body
    );
  }

  uploadRca(incidentId: string, file: FormData) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}/uploadFile`,
      file
    );
  }

  downloadRca(incidentId: string) {
    return this.httpClient.get(
      `${environment.csToolApiIncidentController}/${incidentId}/downloadFile`,
      { responseType: 'blob' }
    );
  }

  deleteRca(incidentId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}/deleteFile`
    );
  }

  deleteIncident(incidentId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}`
    );
  }

  getKnownIssueNotificaitons(
    incidentId: string
  ): Observable<KnownIssueNotifications[]> {
    return this.httpClient.get<KnownIssueNotifications[]>(
      `${environment.csToolApiIncidentController}/notifications/${incidentId}`
    );
  }

  // FIRM TEAM CONTROLLER
  getWaNotificationGroups(): Observable<WaNotificationGroup[]> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<WaNotificationGroup[]>(
      `${environment.csToolApiWaNotificationGroupController}`,
      { params }
    );
  }

  updateWaGroup(groupId: string, body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiWaNotificationGroupController}/${groupId}`,
      body
    );
  }

  createWaGroup(body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiWaNotificationGroupController}`,
      body
    );
  }

  deleteWaGroup(groupId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiWaNotificationGroupController}/${groupId}`
    );
  }

  // INSTATUS CONTOLLER
  getInstatusComponents(): Observable<InstatusComponents[]> {
    return this.httpClient.get<InstatusComponents[]>(
      `${environment.csToolApiInstatusController}/components`
    );
  }

  getInstatusTemplates(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.csToolApiInstatusController}/templates`
    );
  }

  getInstatusIncident(incidentId: string): Observable<InstatusIncidentDetails> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<InstatusIncidentDetails>(
      `${environment.csToolApiInstatusController}/${incidentId}`,
      { params }
    );
  }

  updateInstatusIncident(incidentId: string, body: {}): Observable<any> {
    return this.httpClient.put(
      `${environment.csToolApiInstatusController}/${incidentId}`,
      body
    );
  }

  deleteInstatusIncident(
    instatusIncidentId: string,
    knownIssueIncidentId: string
  ): Observable<any> {
    return this.httpClient.delete(
      `${environment.csToolApiInstatusController}/${instatusIncidentId}/${knownIssueIncidentId}`
    );
  }

  deleteInstatusIncidentUpdate(
    incidentId: string,
    updateId: string
  ): Observable<any> {
    return this.httpClient.delete(
      `${environment.csToolApiInstatusController}/update/${incidentId}/${updateId}`
    );
  }

  createInstatusIncident(
    knownIssueIncidentId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiInstatusController}/${knownIssueIncidentId}`,
      { body }
    );
  }

  addInstatusUpdate(incidentId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiInstatusController}/${incidentId}/incident-updates`,
      body
    );
  }

  editInstatusUpdate(
    incidentId: string,
    incidentUpdateId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.put(
      `${environment.csToolApiInstatusController}/${incidentId}/incident-updates/${incidentUpdateId}`,
      body
    );
  }

  // QUERY BUILDER CONTOLLER
  getAllQueries(): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(`${environment.csToolApi}/query-builder`, {
      params,
    });
  }

  saveQuery(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/query-builder/save-query`,
      body
    );
  }

  deleteQuery(id: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.csToolApi}/query-builder/delete-query/${id}`
    );
  }

  updateQuery(id: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/query-builder/update-query/${id}`,
      body
    );
  }

  // EMAIL TEMPLATES
  getEmailTemplates(fromObject: {}): Observable<EmailTemplates> {
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<EmailTemplates>(
      `${environment.csToolApiEmailTemplatesController}`,
      { params }
    );
  }

  updateEmailTemplates(emailTemplateId, body: {}): Observable<EmailTemplates> {
    return this.httpClient.post<EmailTemplates>(
      `${environment.csToolApiEmailTemplatesController}/${emailTemplateId}`,
      body
    );
  }

  createEmailTemplate(body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiEmailTemplatesController}`,
      body
    );
  }

  deleteEmailTemplate(emailTemplateId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiEmailTemplatesController}/${emailTemplateId}`
    );
  }

  // PARTNER LABEL CONTROLLER
  createPartnerLabel(body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiPartnerLabelController}`,
      body
    );
  }

  getPartnerLabels(partnerId: string): Observable<PartnerLabelsModel> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<CsToolApiResponseModel<PartnerLabelsModel>>(
      `${environment.csToolApiPartnerLabelController}/${partnerId}`,
      { params }
    );
  }

  deletePartnerLabel(labelId: string): Observable<any> {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiPartnerLabelController}/${labelId}`
    );
  }

  // INTERCOM

  getConversation(conversationId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApiIntercomController}/getConversation/${conversationId}`,
      { params }
    );
  }
  // JIRA

  getIssue(ticketId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApiJiraController}/getIssue/${ticketId}`,
      { params }
    );
  }

  updateJiraTicket(body: {}): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.csToolApiJiraController}/updateJiraTicket`,
      body
    );
  }

  // Intercom Review

  getReviewData(): Observable<IntercomReviews> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<IntercomReviews>(
      `${environment.csToolApiReviewController}`,
      { params }
    );
  }

  downloadFile() {
    return this.httpClient.get(
      `${environment.csToolApiReviewController}/export`,
      { responseType: 'blob' }
    );
  }
}
