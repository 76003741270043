import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

export const PAGE_SIZE = 15;

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input() pageSize!: number;
  @Input() page!: number;
  @Input() collectionSize!: number;
  @Output() inputPageEvent = new EventEmitter<number>();

  currentPage!: number;

  constructor() { }

  ngOnInit(): void {
    this.currentPage = this.page;
  }

  sendPageValue(event: number | undefined) {
    this.inputPageEvent.emit(event);
  }

  ngOnChanges(): void {
      this.currentPage = this.page;
  }

}
