import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorInfoModalComponent } from 'src/app/shared/modals/error-info-modal/error-info-modal.component';
import { AuthUserModel } from '../../domains/models/auth-user.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private modalService: NgbModal,
    private clipboard: Clipboard,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {}

  openModal(modalName, size = 'none'): void {
    if (size != 'none') {
      this.modalService.open(modalName, { centered: true, size: size });
    } else {
      this.modalService.open(modalName, { centered: true });
    }
  }

  dismissModal(): void {
    this.modalService.dismissAll();
  }

  dropdownSelected(
    form: FormGroup,
    dopdownSelected: string,
    controlName: string
  ) {
    form.controls[controlName].setValue(dopdownSelected);
  }

  successClass(parameter): string {
    return parameter == 'connected' ||
      parameter == 'stable' ||
      parameter == 200 ||
      parameter == 'success' ||
      parameter == 'ok' ||
      parameter == true ||
      parameter == 'approved' ||
      parameter == 'verified' ||
      parameter == 'active' ||
      parameter == 'ready' ||
      parameter == 'running' ||
      parameter == 'permission_granted'
      ? 'text-success'
      : 'text-danger';
  }

  successState(parameter): boolean {
    return (
      parameter == 'connected' ||
      parameter == 'stable' ||
      parameter == 200 ||
      parameter == 'success' ||
      parameter == 'ok' ||
      parameter == true ||
      parameter == 'approved' ||
      parameter == 'verified' ||
      parameter == 'active' ||
      parameter == 'ready' ||
      parameter == 'running' ||
      parameter == 'permission_granted' ||
      parameter == 'certificated_declined'
    );
  }

  statusBadgeClass(parameter): string {
    return parameter == 'connected' ||
      parameter == 'stable' ||
      parameter == 200 ||
      parameter == 'success' ||
      parameter == 'ok' ||
      parameter == true ||
      parameter == 'approved' ||
      parameter == 'verified' ||
      parameter == 'active' ||
      parameter == 'ready' ||
      parameter == 'running'
      ? 'badge badge-circle badge-success'
      : 'badge badge-circle badge-danger';
  }

  parseErrormessage(err): string {
    return err.error && err.error.errorMessage
      ? err.error.errorMessage
      : err.message;
  }

  parseHubErrorMessage(err): string {
    return err.error && err.error.meta && err.error.meta.developer_message
      ? err.error.meta.developer_message
      : err.message;
  }

  copyText(
    textToCopy: any,
    toastrMessage: string = 'Successfully copied!'
  ): void {
    const success = this.clipboard.copy(textToCopy);
    if (success) {
      this.toastrService.success(toastrMessage);
    } else {
      this.toastrService.error('Failed to copy!');
    }
  }

  isIndianNumber(number): boolean {
    return number.toString().slice(0, 2) == '91';
  }

  setActiveTab(activeTab) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { activeTab: activeTab },
      queryParamsHandling: 'merge',
    });
  }

  secondsToString(seconds): string {
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    return (
      numdays +
      ' days ' +
      numhours +
      ' hours ' +
      numminutes +
      ' minutes ' +
      numseconds +
      ' seconds'
    );
  }

  showSpinner() {
    this.spinnerService.show();
  }

  hideSpinner(dismissModal: boolean = true) {
    this.spinnerService.hide();
    if (dismissModal) {
      this.dismissModal();
    }
  }

  openErrorModal(message, error) {
    this.dismissModal();
    this.hideSpinner();
    const modalInstance: NgbModalRef = this.modalService.open(
      ErrorInfoModalComponent,
      { centered: true }
    );
    modalInstance.componentInstance.title = message;
    modalInstance.componentInstance.errorObject = error;
  }

  replaceUnderscore(text) {
    return text.replace(/_/g, '-');
  }

  showErrorToastr(message: string): void {
    this.toastrService.error(message);
  }
  showErrorToastrAndHideSpinner(message: string): void {
    this.toastrService.error(message);
    this.hideSpinner();
  }

  showSuccessToastr(message: string): void {
    this.toastrService.success(message);
  }

  handleTOSError(err: any): string {
    if (err.error) {
      if (err.error.detail) {
        if (err.error.detail.detail) {
          return err.error.detail.detail;
        } else {
          return this.isJSON(err.error.detail)
            ? JSON.stringify(err.error.detail)
            : err.error.detail;
        }
      }
    }
    return 'An unexpected error occurred. Please try again later. If the errr persists, please post in #cs-tool channel.';
  }

  isJSON(str: string): boolean {
    try {
      const obj = JSON.parse(str);
      return obj && typeof obj === 'object';
    } catch (e) {
      return false;
    }
  }

  hideSpinnerCloseModalAndShowToastr(message: string): void {
    this.dismissModal();
    this.showSuccessToastr(message);
    this.spinnerService.hide();
  }

  removeAllToastr(): void {
    this.toastrService.clear();
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  getKeys(event: any): string[] {
    return Object.keys(event);
  }

  getOverviewCluster(cluster: string): string {
    const match = cluster.match(/^PROD([AC])(\d+)$/);
    if (match) {
      const [, type, number] = match;
      return `prod-${type.toLowerCase()}-${number}`;
    } else {
      return 'prod-mc-1';
    }
  }

  filterUndefinedProperties(obj: { [key: string]: any }): {
    [key: string]: string;
  } {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '')
        acc[key] = value.toString();
      return acc;
    }, {} as { [key: string]: string });
  }

  formatErrorMessage(err: any): string {
    const extractDetail = (obj: any): string => {
      if (typeof obj === 'string') {
        return `Error: ${obj}`;
      }
      if (typeof obj === 'object') {
        return `Error: ${JSON.stringify(obj)}`;
      }
      return `Unexpected error: ${JSON.stringify(obj)}`;
    };

    if (err.error) {
      if (err.error.detail) {
        return extractDetail(err.error.detail);
      }
      if (err.error.message) {
        return extractDetail(err.error.message);
      }
    }
    return `Unexpected error: ${JSON.stringify(err)}`;
  }

  isDirectClient(partnerId: string): boolean {
    const directPartners: string[] = [
      'SvAiK8PA',
      'Qlvm1vPA',
      'KJNS6kPA',
      'sJrUixPA',
      'vXfVgrPA',
      'uFLRmmPA',
      'rHwLIGPA',
      'srMmqpPA',
      'aUjlH3PA',
      'zW8CroPA',
      'bRVwbQPA',
      '3A99H5PA',
      'DPiM0ePA',
      'zH3MuZPA',
    ];
    return directPartners.includes(partnerId);
  }

  checkPermission(user: AuthUserModel, permission: string): boolean {
    if (!user || !user.permissions) {
      return false;
    }
    return user.permissions.includes(permission);
  }

  generateStrongPassword(length: number = 12): string {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

    const allChars =
      uppercaseChars + lowercaseChars + numberChars + specialChars;

    let password = '';

    password +=
      uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
    password +=
      lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    password += numberChars[Math.floor(Math.random() * numberChars.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    for (let i = 4; i < length; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    password = password
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');

    return password;
  }
}
