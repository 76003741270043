<div ngbDropdown class="d-inline-block ml-auto dropdown-container">
  <button type="button" class="btn btn-outline-dark" ngbDropdownToggle>
    Docs
  </button>
  <div ngbDropdownMenu>
    <button type="button" ngbDropdownItem disabled *ngIf="defaultDropdownOption">
      {{ defaultDropdownOption }}
    </button>
    <a
      *ngFor="let dropdownItem of dropdownItems"
      [href]="dropdownItem.link"
      ngbDropdownItem
      [target]="dropdownItem.target"
    >
      {{ dropdownItem.name }}
    </a>
  </div>
</div>
