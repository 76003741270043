import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WabaStackApiService {
  constructor(private httpClient: HttpClient) {}

  disableMfa(appId: string, password: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.wabaStack}/${password}/${appId}/disable-2fa`
    );
  }

  enableMfa(appId: string, code: string, password: string): Observable<any> {
    const body = {
      pin: code
    };
    return this.httpClient.post<any>(
      `${environment.wabaStack}/${password}/${appId}/enable-2fa`, body
    );
  }
}
