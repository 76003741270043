import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Messages } from '../domains/models/sendgrid.model';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class SendgridService {
  sendgridRoute: string = `${environment.tppCsToolAPI.url}/v1/sendgrid`;

  constructor(private httpClient: HttpClient) {}

  getMessagesByEmail(email: string): Observable<Messages> {
    return this.httpClient.get<Messages>(
      `${this.sendgridRoute}/messages/${email}`,
      { params }
    );
  }

  getMessageByMessageId(messageId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.sendgridRoute}/message/${messageId}`,
      { params }
    );
  }

  getBounceByEmail(email: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.sendgridRoute}/suppression/bounces/${email}`,
      { params }
    );
  }

  deleteBounceByEmail(email: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.sendgridRoute}/suppression/bounces/${email}`
    );
  }

  getBlockByEmail(email: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.sendgridRoute}/suppression/blocks/${email}`,
      { params }
    );
  }

  deleteBlockByEmail(email: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.sendgridRoute}/suppression/blocks/${email}`
    );
  }

  getSpamByEmail(email: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.sendgridRoute}/suppression/spam_reports/${email}`,
      { params }
    );
  }

  deleteSpamByEmail(email: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.sendgridRoute}/suppression/blocks/${email}`
    );
  }

  getUnsubscribeByEmail(email: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.sendgridRoute}/suppression/unsubscribe/${email}`,
      { params }
    );
  }

  deleteUnsubscribeByEmail(email: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.sendgridRoute}/suppression/unsubscribe/${email}`
    );
  }
}
