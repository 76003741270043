<button
  type="button"
  class="copy-btn me-2"
  [ngbTooltip]="tooltip"
  (click)="copyText(value)"
>
  <img
    src="../../../assets/images/icons/clipboard-icon.svg"
    alt="clipboard-icon"
  />
</button>
