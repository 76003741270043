import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { ChannelScanNotificaiton } from '../../domains/models/channel-scan-notificaiton.module';
import { HubService } from '../hub/hub.service';
import { HelperService } from '../helper/helper.service';
import { UserModel } from '../../domains/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  initialState: ChannelScanNotificaiton = {
    isCompleted: false,
    payload: null,
    error: false,
    isRunning: true,
  };

  private _mfaNotification$ = new BehaviorSubject(this.initialState);
  private _webhookNotification$ = new BehaviorSubject(this.initialState);
  private _channelBalanceNotification$ = new BehaviorSubject(this.initialState);
  private _gatewayNotification$ = new BehaviorSubject(this.initialState);
  private _metricsNotification$ = new BehaviorSubject(this.initialState);
  private _databaseNotification$ = new BehaviorSubject(this.initialState);
  private _multiWebhookNotification$ = new BehaviorSubject(this.initialState);
  private _refreshGateway$ = new Subject<any>();
  private _refreshMetrics$ = new Subject<any>();
  private _refreshDatabase$ = new Subject<any>();
  private _csToolUser$ = new BehaviorSubject({});
  private _user$ = new BehaviorSubject({});
  mfaNotification$ = this._mfaNotification$.asObservable();
  webhookNotification$ = this._webhookNotification$.asObservable();
  channelBalanceNotification$ =
    this._channelBalanceNotification$.asObservable();
  gatewayNotification$ = this._gatewayNotification$.asObservable();
  metricsNotification$ = this._metricsNotification$.asObservable();
  databaseNotification$ = this._databaseNotification$.asObservable();
  multiWebhookNotification$ = this._multiWebhookNotification$.asObservable();
  refreshGateway$ = this._refreshGateway$.asObservable();
  refreshMetrics$ = this._refreshMetrics$.asObservable();
  refreshDatabase$ = this._refreshDatabase$.asObservable();
  csToolUser$ = this._csToolUser$.asObservable();
  user$ = this._user$.asObservable();

  constructor(
    private hubService: HubService,
    private helperService: HelperService
  ) {}

  setPayload(payload: ChannelScanNotificaiton, type: string) {
    if (type == 'mfa') {
      this._mfaNotification$.next(payload);
    } else if (type == 'webhook') {
      this._webhookNotification$.next(payload);
    } else if (type == 'channelBalance') {
      this._channelBalanceNotification$.next(payload);
    } else if (type == 'gateway') {
      this._gatewayNotification$.next(payload);
    } else if (type == 'metrics') {
      this._metricsNotification$.next(payload);
    } else if (type == 'database') {
      this._databaseNotification$.next(payload);
    } else if (type == 'multiWebhook') {
      this._multiWebhookNotification$.next(payload);
    }
  }

  setUser(user: UserModel): void {
    this._csToolUser$.next(user);
  }

  setUserValue(user): void {
    this._user$.next(user);
  }

  setRefreshData(type: string) {
    if (type == 'gateway') {
      this._gatewayNotification$.next(this.initialState);
      this._refreshGateway$.next(true);
    } else if (type == 'metrics') {
      this._metricsNotification$.next(this.initialState);
      this._refreshMetrics$.next(true);
    } else {
      this._databaseNotification$.next(this.initialState);
      this._refreshDatabase$.next(true);
    }
  }

  getBillingData(
    partnerId: string,
    clientId: string,
    projectId: string,
    channelId: string,
    thisYear: string,
    reset: boolean = false
  ): void {
    if (reset) this.setPayload(this.initialState, 'channelBalance');
    combineLatest([
      this.hubService.getBillingInfo(partnerId, clientId, reset),
      this.hubService.getChannelBalance(
        partnerId,
        clientId,
        projectId,
        channelId,
        thisYear,
        reset
      ),
    ]).subscribe({
      next: (res) => {
        this.setPayload(
          {
            isCompleted: true,
            payload: res,
            error: false,
            errorMessage: '',
          },
          'channelBalance'
        );
      },
      error: (err) => {
        this.setPayload(
          {
            isCompleted: true,
            payload: null,
            error: true,
            errorMessage: `Failed to get billing information. Error ${this.helperService.parseHubErrorMessage(
              err
            )}`,
          },
          'channelBalance'
        );
      },
    });
  }
}
