import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-info-modal',
  templateUrl: './error-info-modal.component.html',
  styleUrls: ['./error-info-modal.component.scss'],
})
export class ErrorInfoModalComponent implements OnInit {
  @Input() title: string;
  @Input() errorObject: any;

  constructor(protected activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.errorObject.hasOwnProperty('headers')) {
      delete this.errorObject.headers;
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
