<input
  type="checkbox"
  id="toggle-button-checkbox"
  (change)="changed.emit($event.target['checked'])"
  [checked]="checked"
/>
<label class="toggle-button-switch" for="toggle-button-checkbox"></label>
<div class="toggle-button-text">
  <div class="toggle-button-text-on">ON</div>
  <div class="toggle-button-text-off">OFF</div>
</div>
