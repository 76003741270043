import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HubService } from './services/hub/hub.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './authentication/authentication.service';
import { CacheService } from './services/cache/cache.service';
import { HistoryService } from './services/history/history.service';
import { SharedDataService } from './services/shared-data/shared-data.service';
import { CsToolApiService } from './services/cs-tool-api/cs-tool-api.service';
import { WabaStackApiService } from './services/wabaStackApi/waba-stack-api.service';
import { UsersService } from './services/userService/users.service';
import { OboToSharedService } from './services/obo-to-shared/obo-to-shared.service';
import { DatabricksService } from './services/databricks/databricks.service';
import { JiraService } from './services/jira.service';
import { IntercomService } from './services/intercom.service';
import { PltAPIService } from './services/plt.api.service';
import { SendgridService } from './services/sendgrid.service';
import { StackService } from './services/stack.service';
import { PbsService } from './services/pbs.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    HubService,
    AuthenticationService,
    CacheService,
    HistoryService,
    SharedDataService,
    CsToolApiService,
    WabaStackApiService,
    UsersService,
    OboToSharedService,
    DatabricksService,
    JiraService,
    IntercomService,
    PltAPIService,
    SendgridService,
    StackService,
    PbsService,
  ],
})
export class CoreModule {}
