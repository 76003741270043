import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { UserToken } from '../domains/models/user-token.module';
import { HubService } from '../services/hub/hub.service';
import { UsersService } from '../services/userService/users.service';
import { SharedDataService } from '../services/shared-data/shared-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();

  constructor(
    private hubService: HubService,
    private toastrService: ToastrService,
    private router: Router,
    private userService: UsersService,
    private sharedDataService: SharedDataService
  ) {
    this.tokenExpired();
  }

  loginWithEmailAndPassword(
    email: string,
    password: string
  ): Observable<UserToken> {
    localStorage.setItem('loginType', 'old');
    return this.hubService.loginUserWithEmailAndPassowrd(email, password).pipe(
      tap((userAcessToken: UserToken) => {
        if (userAcessToken.access_token) {
          this._isLoggedIn$.next(true);
          this.checkUser(email);
        }
      })
    );
  }

  tokenExpired(): void {
    if (!!localStorage.getItem('hub_token')) {
      const expiry = JSON.parse(
        atob(localStorage.getItem('hub_token').split('.')[1])
      );
      if (expiry.exp * 1000 < Date.now()) {
        this.toastrService.error('Token expired, please log in again.');
        this.logout();
      } else {
        this._isLoggedIn$.next(true);
      }
    }
  }

  logout(): void {
    const originalUrl = window.location.href;
    const parsedUrl = new URL(originalUrl);
    const path = parsedUrl.pathname + parsedUrl.search;
    localStorage.setItem('redirect_url', path);
    localStorage.removeItem('loginType');
    Object.keys(localStorage).forEach(key => {
      if (key !== 'redirect_url') {
        localStorage.removeItem(key);
      }
    });
    this._isLoggedIn$.next(false);
    this.router.navigate(['/login']);

  }

  private checkUser(email: string) {
    this.userService.checkUser(email, true).subscribe({
      next: (res) => {
        this.sharedDataService.setUser(res);
      },error: (err) => {}
    });
  }
}
