<div class="modal-header">
  <div class="d-inline-flex align-items-center">
    <h4 class="modal-title me-2">Escalations and Complaints</h4>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <!-- Button group for issue type selection, centered -->
  <div class="d-flex justify-content-center mb-3">
    <div class="btn-group" role="group" aria-label="Issue type">
      <input type="radio" class="btn-check" name="issueType" id="jiraRadio" value="jira" autocomplete="off"
        [(ngModel)]="type" (ngModelChange)="onTypeChange()">
      <label class="btn btn-outline-dark" for="jiraRadio">TICKET</label>

      <input type="radio" class="btn-check" name="issueType" id="intercomRadio" value="intercom" autocomplete="off"
        [(ngModel)]="type" (ngModelChange)="onTypeChange()">
      <label class="btn btn-outline-dark" for="intercomRadio">CHAT</label>
    </div>
  </div>

  <div *ngIf="type=='intercom'">
    <form [formGroup]="form">
      <div class="form-group">
        <small>Please enter the conversation ID. Example: 15432</small><br>
        <label>Conversation ID</label>
        <div class="input-group">
          <input type="text" class="form-control no-outline" formControlName="conversationId">
          <button class="btn btn-primary" type="button" [disabled]="!form.get('conversationId').valid"
            (click)="getConversation()">Search</button>
          <a [href]="'https://app.intercom.com/a/apps/w5qvfu58/inbox/inbox/conversation/' + form.get('conversationId').value"
            target="_blank" class="btn btn-link"><i class="bi bi-box-arrow-up-right"></i></a>
        </div>
      </div>
      <div class="form-group pt-2" *ngIf="conversationExists">
        <span class="fs-6">Please select tags that should be attached to the conversation</span>
        <div class="d-flex flex-wrap justify-content-center">
          <div *ngFor="let tag of tags" class="p-2">
            <input type="checkbox" [checked]="selectedLabels.includes(tag.id.toString())" (change)="updateTags(tag)"
              id="{{tag.name}}" />
            <label for="{{tag.name}}" class="fs-6 ms-1">{{ tag.name }}</label>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="conversationExists">
        <label>Reason</label>
        <textarea class="form-control" rows="2" formControlName="reason"
          placeholder="Please provide the reason for adding/removing escalated or complaint tag."></textarea>
      </div>
    </form>
  </div>
  <div *ngIf="type=='jira'">
    <form [formGroup]="jiraForm">
      <div class="form-group">
        <small>Please enter the ticket ID. Example: CS-15432</small><br>
        <label>Ticket ID</label>
        <div class="input-group">
          <input type="text" class="form-control no-outline" formControlName="issue_id">
          <button class="btn btn-primary" type="button" [disabled]="!jiraForm.get('issue_id').valid"
            (click)="getIssue()">Search</button>
          <a [href]="'https://360dialog.atlassian.net/jira/servicedesk/projects/CS/queues/custom/54/' + jiraForm.get('issue_id').value"
            target="_blank" class="btn btn-link"><i class="bi bi-box-arrow-up-right"></i></a>
        </div>
      </div>
      <div class="form-group pt-2" *ngIf="ticketExists">
        <span class="fs-6">Please select labels that should be attached to the ticket</span>
        <div class="d-flex flex-wrap justify-content-center">
          <div *ngFor="let label of labels" class="p-2">
            <input type="checkbox" [checked]="selectedLabels.includes(label.name)" (change)="updateLabels(label)"
              id="{{label.name}}" />
            <label for="{{label.name}}" class="fs-6 ms-1">{{ label.name }}</label>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="ticketExists">
        <label>Reason</label>
        <textarea class="form-control" rows="2" formControlName="reason"
          placeholder="Please provide the reason for adding/removing escalated or complaint tag."></textarea>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer" *ngIf="type=='intercom'">
  <button type="button" class="btn-modal-proceed" [disabled]="!form.valid" (click)="updateIntercomChat()">
    Update
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">
    Close
  </button>
</div>
<div class="modal-footer" *ngIf="type=='jira'">
  <button type="button" class="btn-modal-proceed" [disabled]="!jiraForm.valid" (click)="updateJiraTicket()">
    Update
  </button>
  <button type="button" class="btn-modal-close" (click)="close()">
    Close
  </button>
</div>