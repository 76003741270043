<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col"></div>
      <ngx-json-viewer [json]="errorObject"></ngx-json-viewer>
    </div>
  </div>
</div>
