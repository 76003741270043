<div ngbDropdown class="d-inline-block ml-auto dropdown-container w-100">
  <button type="button" class="btn btn-outline-dark w-100 rounded-0" ngbDropdownToggle>
    {{ selectedItem ? selectedItem : defaultDropdownOption }}
  </button>
  <div ngbDropdownMenu>
    <button type="button" ngbDropdownItem disabled>
      {{ defaultDropdownOption }}
    </button>
    <button
      *ngFor="let dropdownItem of dropdownItems"
      ngbDropdownItem
      type="button"
      (click)="dropdownItemSelected($event)"
      [value]="dropdownItem"
    >
      {{ dropdownItem }}
    </button>
  </div>
</div>
