import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import e from 'express';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Allocation } from 'src/app/core/domains/models/allocation.model';
import { UserModel } from 'src/app/core/domains/models/user.model';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub/hub.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { DeletedChannelComponent } from 'src/app/shared/modals/deleted-channel/deleted-channel.component';
import { FacebookIdValidatorComponent } from 'src/app/shared/modals/facebook-id-validator/facebook-id-validator.component';
import { MigratePhoneModalComponent } from 'src/app/shared/modals/migrate-phone-modal/migrate-phone-modal.component';
import { SetPaymentMethodModalComponent } from 'src/app/shared/modals/set-payment-method-modal/set-payment-method-modal.component';
import { SubscribeAppModalComponent } from 'src/app/shared/modals/subscribe-app-modal/subscribe-app-modal.component';
import { TwoInputModalComponent } from 'src/app/shared/modals/two-input-modal/two-input-modal.component';
import { AttachTagModalComponent } from './modals/attach-tag-modal/attach-tag-modal.component';
import { PltAPIService } from 'src/app/core/services/plt.api.service';
import { SendgridComponent } from 'src/app/shared/modals/sendgrid/sendgrid.component';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements OnInit, OnDestroy {
  displayColumns: Array<string> = ['NAME', 'DESCRIPTION', 'ACTION'];

  form: FormGroup;
  revokeCreditLineForm: FormGroup;
  migrateTemplatesForm: FormGroup;

  actionError: boolean = false;
  actionMessage: string = '';
  subscriptions: Subscription[] = [];
  isAdmin: boolean = false;

  allocationIdExists: boolean = false;
  creditLineDeleted: boolean = false;
  allocationObject: Allocation = {};
  verifyObject = {};

  channelId: string = null;
  clientPcrInfo: any = null;

  constructor(
    private modalService: NgbModal,
    private hubService: HubService,
    private formBuilder: FormBuilder,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService,
    private pltApiService: PltAPIService,
    private helperService: HelperService,
    private sharedDataService: SharedDataService,
    private csToolApiService: CsToolApiService
  ) {}

  ngOnInit(): void {
    this.checkRoles();
    this.generateForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  openModal(modalName, size = 'lg') {
    this.allocationObject = {};
    this.verifyObject = {};
    this.actionError = false;
    this.allocationIdExists = false;
    this.actionMessage = '';
    this.form.reset();
    this.revokeCreditLineForm.reset();
    this.modalService.open(modalName, { centered: true, size: size });
  }

  openModalClear(modalName, formToReset: string = ''): void {
    if (formToReset == 'form') {
      this.clientPcrInfo = null;
      this.form.reset();
    }
    this.modalService.open(modalName, { centered: true, size: 'lg' });
  }

  getClientPcrInfo(): void {
    this.clientPcrInfo = null;
    this.helperService.showSpinner();
    this.hubService
      .getClientPcrInfo(
        this.form.controls['partnerId'].value.trim(),
        this.form.controls['clientId'].value.trim()
      )
      .subscribe({
        next: (res) => (this.clientPcrInfo = res),
        error: (err) =>
          this.helperService.openErrorModal('Fail to get client PCR info', err),
        complete: () => this.helperService.hideSpinner(false),
      });
  }

  deleteEsUser(): void {
    this.spinnerService.showBySelector('deleteESModal');
    this.hubService
      .deleteUser(this.form.controls['userEmail'].value.trim())
      .subscribe({
        error: (err) => {
          this.spinnerService.hideBySelector('deleteESModal');
          this.actionError = true;
          this.actionMessage = `Failed to delete the user! Error: ${this.helperService.parseErrormessage(
            err
          )}`;
        },
        complete: () => {
          this.spinnerService.hideBySelector('deleteESModal');
          this.modalService.dismissAll();
          this.toastrService.success('User deleted successfully!');
        },
      });
  }

  reCreateStack(): void {
    this.spinnerService.showBySelector('undeleteStackModal');
    const body = { hub_username: localStorage.getItem('hub_email') ?? '' };
    this.pltApiService
      .reCreateStack(this.form.controls['appId'].value.toString().trim(), body)
      .subscribe({
        error: (err) => {
          this.spinnerService.hideBySelector('undeleteStackModal');
          this.actionError = true;
          this.actionMessage = `Failed to create stack! Error: ${this.helperService.parseErrormessage(
            err
          )}`;
        },
        complete: () => {
          this.spinnerService.hideBySelector('undeleteStackModal');
          this.modalService.dismissAll();
          this.toastrService.success('Stack re-created successfully!');
        },
      });
  }

  clear() {
    this.channelId = null;
  }

  flagForReOnboarding(): void {
    this.helperService.showSpinner();
    this.hubService.flagForReOnboarding(this.channelId).subscribe({
      next: () => {
        this.helperService.showSuccessToastr('Flagged successfully.');
        this.helperService.hideSpinner();
        this.clear();
      },
      error: (err) => {
        this.helperService.openErrorModal(
          'Fail to flag number for re-onboarding',
          err
        );
      },
    });
  }
  portNumber(): void {
    this.helperService.showSpinner();
    this.hubService
      .portNumber(
        this.form.controls['partnerId'].value.trim(),
        this.form.controls['clientId'].value.trim(),
        this.form.controls['wabaAccId'].value.trim(),
        this.form.controls['phoneNumber'].value.trim()
      )
      .subscribe({
        next: (res) => {
          this.helperService.showSuccessToastr('Number ported successfully.');
          this.actionMessage = JSON.stringify(res);
        },
        error: (err) => {
          this.helperService.openErrorModal('Failed to port the number', err);
        },
        complete: () => {
          this.actionError = false;
          this.helperService.hideSpinner(false);
        },
      });
  }

  validateFbId() {
    this.modalService.open(FacebookIdValidatorComponent, {
      size: 'lg',
      centered: true,
    });
  }

  openSendgridModal() {
    const modalInstance: NgbModalRef = this.modalService.open(
      SendgridComponent,
      { size: 'xl', centered: true }
    );
    modalInstance.componentInstance.action = true;
  }

  openDeletedChannelModal() {
    this.modalService.open(DeletedChannelComponent, {
      size: 'xl',
      centered: true,
    });
  }

  setPaymentMethod(): void {
    this.modalService.open(SetPaymentMethodModalComponent, { centered: true });
  }

  subscribeApp(): void {
    this.modalService.open(SubscribeAppModalComponent, { centered: true });
  }

  migratePhone(): void {
    this.modalService.open(MigratePhoneModalComponent, { centered: true });
  }

  allowOutboundMessages(): void {
    const modalInstance: NgbModalRef = this.modalService.open(
      TwoInputModalComponent,
      { centered: true }
    );
    modalInstance.componentInstance.title = 'Allow outbound messages';
    modalInstance.componentInstance.inputFieldOne = 'Partner ID';
    modalInstance.componentInstance.inputFieldTwo = 'Client ID';
    modalInstance.componentInstance.confirmationText = 'Proceed';
    modalInstance.result.then((dismissData: any) => {
      if (dismissData) {
        this.helperService.showSpinner();
        this.hubService
          .allowOutboundMessages(
            modalInstance.componentInstance.editInputOne,
            modalInstance.componentInstance.editInputTwo
          )
          .subscribe({
            error: (err) =>
              this.helperService.openErrorModal(
                'Fail to allow outbound messages',
                err
              ),
            complete: () =>
              this.helperService.hideSpinnerCloseModalAndShowToastr(
                'Outbound messages enabled.'
              ),
          });
      }
    });
  }

  migrateTemplates(): void {
    this.helperService.showSpinner();
    this.csToolApiService
      .migrateTemplates({ ...this.migrateTemplatesForm.value })
      .subscribe({
        next: () => {
          this.helperService.showSuccessToastr(
            'Templates migrated successfully.'
          );
          this.migrateTemplatesForm.reset();
        },
        error: (err) => {
          this.helperService.openErrorModal('Fail to migrate templates.', err);
          this.migrateTemplatesForm.reset();
        },
      });
  }

  isObjectEmpty(obj): boolean {
    return this.helperService.isObjectEmpty(obj);
  }

  getAllocationId(): void {
    this.helperService.showSpinner();
    this.actionError = false;
    this.actionMessage = '';
    this.allocationIdExists = false;
    this.creditLineDeleted = false;
    this.csToolApiService
      .getAllocationId(
        this.revokeCreditLineForm.controls['business_manager_id'].value
      )
      .subscribe({
        next: (res) => {
          if (res?.['success']) {
            if (this.helperService.isObjectEmpty(res?.['data']?.['data'])) {
              this.actionError = true;
              this.actionMessage =
                'The allocation object is empty meaning that the credit line is probably not attached';
            } else {
              this.allocationObject = res?.['data']?.['data']?.[0];
              this.revokeCreditLineForm
                .get('allocation_id')
                .setValue(this.allocationObject.id);
              this.allocationIdExists = true;
            }
          } else {
            this.actionError = true;
            this.actionMessage = res.message
              ? res.message
              : 'Fail to fetch allocation ID.';
            this.revokeCreditLineForm.reset();
          }
          this.helperService.hideSpinner(false);
        },
        error: (err) => {
          this.revokeCreditLineForm.reset();
          this.helperService.openErrorModal(
            'Fail to fetch allocation ID.',
            err
          );
        },
      });
  }

  deleteCreditLine(): void {
    this.helperService.showSpinner();
    this.actionError = false;
    this.actionMessage = '';
    this.creditLineDeleted = false;
    this.csToolApiService
      .deleteCreditLine(
        this.revokeCreditLineForm.controls['allocation_id'].value
      )
      .subscribe({
        next: (res) => {
          if (res?.['success']) {
            this.helperService.showSuccessToastr(
              'Credit line removed successfully.'
            );
            this.verifyDeleted();
          } else {
            this.actionError = true;
            this.actionMessage = res.message
              ? res.message
              : 'Fail to delete credit line.';
            this.helperService.hideSpinner(false);
          }
        },
        error: (err) => {
          this.revokeCreditLineForm.reset();
          this.helperService.openErrorModal('Fail to delete credit line.', err);
        },
      });
  }

  verifyDeleted(): void {
    this.csToolApiService
      .verifyCreditLineDeteled(
        this.revokeCreditLineForm.controls['allocation_id'].value
      )
      .subscribe({
        next: (res) => {
          this.verifyObject = res?.['data'];
          this.creditLineDeleted = true;
          this.helperService.hideSpinner(false);
        },
      });
  }

  attachTag(): void {
    this.modalService.open(AttachTagModalComponent, { centered: true });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      userEmail: new FormControl(null, [Validators.required]),
      appId: new FormControl(null),
      partnerId: new FormControl(null),
      clientId: new FormControl(null),
      wabaAccId: new FormControl(null),
      phoneNumber: new FormControl(null),
      userPassword: new FormControl(null),
    });

    this.revokeCreditLineForm = this.formBuilder.group({
      business_manager_id: new FormControl('', [Validators.required]),
      allocation_id: new FormControl('', [Validators.required]),
    });

    this.migrateTemplatesForm = this.formBuilder.group({
      source_waba: new FormControl('', [Validators.required]),
      destination_waba: new FormControl('', [Validators.required]),
    });
  }

  private checkRoles() {
    this.subscriptions.push(
      this.sharedDataService.csToolUser$.subscribe((user: UserModel) => {
        if (user?.roles?.includes('Admin')) {
          this.isAdmin = true;
        }
      })
    );
  }
}
