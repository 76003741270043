import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AuthenticationService as AuthService} from 'src/app/core/services/auth.service';
import { UserModel } from 'src/app/core/domains/models/user.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { UsersService } from 'src/app/core/services/userService/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  subscriptions: Subscription[] = [];
  isAdmin: boolean = false;
  isTeamLead: boolean = false;
  isTosViewer: boolean = false;
  shouldShowSearch: boolean = false;
  stickyTop: boolean = false;
  form: FormGroup;
  user: UserModel;
  topBarData = [
    {
      route: '/phone',
      text: 'Integrations',
    },
    {
      route: '/action',
      text: 'Action',
    },
    {
      route: '/partners',
      text: 'Partners',
    },
    {
      route: '/known-issues',
      text: 'Known Issues',
    },
    {
      route: '/email-templates',
      text: 'Email Templates',
    }
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private usersService: UsersService,
    private authService: AuthService
  ) {}

ngOnInit(): void {
  this.checkRoles();
  const currentUrl = this.router.url;
  this.handleRouteChange(currentUrl);
  this.router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      this.handleRouteChange(event.url);
    }
  });
}

  private handleRouteChange(url: string): void {
  const splittedUrl = url.split('/');
  if (splittedUrl[2] == 'partner') {
    this.shouldShowSearch = true;
    this.stickyTop = true;
  } else {
    this.shouldShowSearch = false;
    this.stickyTop = false;
  }
}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  logout(): void {
    if(localStorage.getItem('loginType') === 'new') {
      this.authService.clearAuthState();
    } else {
      this.authenticationService.logout();
    }
  }

  savePreference(): void {
    this.helperService.showSpinner();
    this.usersService.updateUserPreference(this.user?.id, {preference: {...this.form.value}}).subscribe({
      next: (res) => {
        if(res?.['success']) {
          this.helperService.showSuccessToastr(res?.['message']);
          this.user = res?.['data'];
          this.sharedDataService.setUser(this.user);
        } else {
          this.helperService.showErrorToastr(res?.['message']);
        }
      },
      error: (err) => this.helperService.openErrorModal(`Failed to update ${this.user?.id}`, err),
      complete: () => this.helperService.hideSpinner()
    });
  }

  setSearchParameters(event) {
    this.router.navigate(['/phone'], {
      queryParams: {
        [event.searchType]: event.searchInput,
        searchType: 'single',
      },
    });
  }

  openModal(modalName): void {
    this.helperService.openModal(modalName);
  }

  hideNaviagtion() {
    const mobileWidthThreshold = 768;
    if (window.innerWidth < mobileWidthThreshold) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }

  private checkRoles() {
    this.subscriptions.push(
      this.sharedDataService.csToolUser$.subscribe((user: UserModel) => {
        if(user) {
          if(!environment.production) {
            this.isTosViewer = true
          } else {
            if(user.roles?.includes('TOS Viewer')) {
              this.isTosViewer = true;
            }
          }
          this.user = user;
          this.generateForm();
          if(user.roles?.includes('Admin')) {
            this.isAdmin = true;
          }
          if(user.roles?.includes('Team Lead')) {
            this.isTeamLead = true;
          }
          if(user.roles?.includes('TOS Viewer')) {
            this.isTeamLead = true;
          }
        }
      }
    ));
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      phone_number: new FormControl(this.user?.preferences?.['phone_number'] ?? "", Validators.required)
    });
  }
}
