import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-single-input-modal',
  templateUrl: './single-input-modal.component.html',
  styleUrls: ['./single-input-modal.component.scss'],
})
export class SingleInputModalComponent implements OnInit {
  form: FormGroup;
  title: string = '';
  inputFieldName: string = '';
  btnLabel: string = 'Save';
  editInput: string = '';
  maxLength: number = null;
  inputType: string = 'text';

  constructor(
    protected activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  confirm() {
    this.activeModal.close(true);
    this.editInput = this.form.controls['editValue'].value;
  }

  close() {
    this.activeModal.dismiss();
  }

  private generateForm(): void {
    if (this.maxLength) {
      this.form = this.formBuilder.group({
        editValue: new FormControl(null, [
          Validators.required,
          Validators.maxLength(this.maxLength),
        ]),
      });
    } else {
      this.form = this.formBuilder.group({
        editValue: new FormControl(null, [Validators.required]),
      });
    }
  }
}
